import React, { useEffect, useState } from "react";
import { Card, Row, Col, FormControl, InputGroup, Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FaGem, FaTimes } from 'react-icons/fa';
import axios from "axios";
import debounce from "lodash.debounce";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import CollegeProfile from "./CollegeProfile";
import Summary from "./Summary";
import CollegeRounds from "../components/CollegeRounds";
import "../assets/css/CollegeList.css"; // Add this line for custom CSS


const CollegeList = () => {

  useEffect(() => {
    document.title = "College List | YouElite";
  }, []);


  const [colleges, setColleges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showRoundsModal, setShowRoundsModal] = useState(false);
  const [userSAT, setUserSAT] = useState(null);
  const [currentCollegeId, setCurrentCollegeId] = useState(null);
  const [currentCollegeName, setCurrentCollegeName] = useState('');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false); // New state for the modal
  const navigate = useNavigate(); // Initialize the navigation hook
  const user = JSON.parse(localStorage.getItem("user")); 
  const userType = user ? user.user_type : 1; 


  useEffect(() => {
    const fetchCollegeList = async () => {
      const user = localStorage.getItem("user");
      if (!user) {
        setLoading(false); // Stop loading if no user
        return;
      }

      const { token } = JSON.parse(user);

      try {
        const response = await axios.get("https://api.student.youelite.net/api/user/colleges", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const collegeList = response.data;
        const collegeDetailsPromises = collegeList.map(college =>
          axios.get(`https://api.student.youelite.net/api/colleges/${college.college_id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }).then(res => ({
            ...res.data,
            userCollegeId: college.id
          }))
        );
        const collegeDetailsResponses = await Promise.all(collegeDetailsPromises);
        setColleges(collegeDetailsResponses);

        const userResponse = await axios.get("https://api.student.youelite.net/api/user", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setUserSAT(parseInt(userResponse.data.sat_score));
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false); // Stop loading once the data is fetched
      }
    };

    fetchCollegeList();
  }, []);

  const fetchSuggestions = debounce(async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    const user = localStorage.getItem("user");
    if (!user) {
      return;
    }

    const { token } = JSON.parse(user);

    try {
      const response = await axios.get(`https://api.student.youelite.net/api/colleges?search=${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setSuggestions(response.data.data);
    } catch (error) {
      console.error("Failed to fetch suggestions:", error);
    }
  }, 300);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    fetchSuggestions(query);
    setShowSuggestions(true);
  };

  const handleSearchBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200);
  };

  const handleSearchFocus = () => {
    if (suggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleSearch = async () => {
    if (!searchQuery) return;

    const user = localStorage.getItem("user");
    if (!user) {
      return;
    }

    const { token } = JSON.parse(user);

    try {
      const response = await axios.get(`https://api.student.youelite.net/api/colleges?search=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setSearchResults(response.data.data);
    } catch (error) {
      console.error("Failed to search colleges:", error);
    }
  };

  const handleAddCollege = async (college) => {
    if (colleges.some(existingCollege => existingCollege.UNITID === college.UNITID)) {
        console.warn("College is already in the list");
        return;
    }

    const user = localStorage.getItem("user");
    if (!user) {
        return;
    }

    const { token } = JSON.parse(user);

    try {
        const response = await axios.post("https://api.student.youelite.net/api/user/colleges", { college_id: college.UNITID }, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        const newCollege = response.data;
        setColleges([...colleges, { ...college, userCollegeId: newCollege.id }]);
        setShowSuggestions(false);
    } catch (error) {
        console.error("Failed to add college:", error);
        if (error.response && error.response.data.error_code === 'PLAN_LIMIT_REACHED') {
            setShowUpgradeModal(true); // Trigger the modal if the plan limit is reached
        }
    }
  };
  const handleRemoveCollege = async (userCollegeId) => {
    const user = localStorage.getItem("user");
    if (!user) {
      return;
    }

    const { token } = JSON.parse(user);

    try {
      await axios.delete(`https://api.student.youelite.net/api/user/colleges/${userCollegeId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setColleges(colleges.filter(college => college.userCollegeId !== userCollegeId));
    } catch (error) {
      console.error("Failed to remove college:", error);
      if (error.response && error.response.status === 404) {
        setColleges(colleges.filter(college => college.userCollegeId !== userCollegeId));
      }
    }
  };

  const renderUpgradeModal = () => (
    <Modal show={showUpgradeModal} onHide={() => setShowUpgradeModal(false)} centered>
        <Modal.Body className="text-center p-4" style={{ position: 'relative' }}>
            {/* Close Button in the body */}
            <FaTimes 
                size={24} 
                color="#6c757d" 
                style={{ 
                    position: 'absolute', 
                    top: '10px', 
                    right: '10px', 
                    cursor: 'pointer' 
                }} 
                onClick={() => setShowUpgradeModal(false)}
            />

            <FaGem size={48} color="#6c757d" />
            <h4 className="my-4">You Can't Bribe Your Way Into College... But This Is Close!</h4>
            <p>You’re smart, but even Einstein needed help.</p>
            <p>
            For just <del>$39.99</del> <span style={{ color: '#d4af37', fontWeight: 'bold' }}>$14.99</span>, unlock the full college prep toolkit and stop winging it. Let’s get you into your dream college—no guesswork required!
            </p>
            <Button variant="success" size="lg" onClick={handleUpgradeClick}>Upgrade and Be Smarter Than Einstein!</Button>
        </Modal.Body>
    </Modal>
  );

  const handleUpgradeClick = () => {
    setShowUpgradeModal(false);  // Close the modal
    navigate('/plans');  // Navigate to the /plans route
  };


  const handleCollegeClick = async (college) => {
    const user = localStorage.getItem("user");
    if (!user) {
      return;
    }

    const { token } = JSON.parse(user);

    try {
      const response = await axios.get(`https://api.student.youelite.net/api/colleges/${college.UNITID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setSelectedCollege(response.data);
      setShowProfileModal(true);
    } catch (error) {
      console.error("Failed to fetch college details:", error);
    }
  };

  const getDisplayCost = (college) => {
    if (college.NPT4_PUB && college.NPT4_PUB !== "NULL") {
      return `$${college.NPT4_PUB}`;
    } else if (college.NPT4_PRIV && college.NPT4_PRIV !== "NULL") {
      return `$${college.NPT4_PRIV}`;
    } else {
      return "N/A";
    }
  };

  const formatAcceptanceRate = (rate) => {
    if (rate && rate !== "NULL") {
      const percentage = (parseFloat(rate) * 100).toFixed(1);
      return `${percentage}%`;
    }
    return "N/A";
  };

  const getRegionName = (regionCode) => {
    const regionNames = {
      0: "U.S. Service Schools",
      1: "New England (CT, ME, MA, NH, RI, VT)",
      2: "Mid East (DE, DC, MD, NJ, NY, PA)",
      3: "Great Lakes (IL, IN, MI, OH, WI)",
      4: "Plains (IA, KS, MN, MO, NE, ND, SD)",
      5: "Southeast (AL, AR, FL, GA, KY, LA, MS, NC, SC, TN, VA, WV)",
      6: "Southwest (AZ, NM, OK, TX)",
      7: "Rocky Mountains (CO, ID, MT, UT, WY)",
      8: "Far West (AK, CA, HI, NV, OR, WA)",
      9: "Outlying Areas (AS, FM, GU, MH, MP, PR, PW, VI)"
    };
    return regionNames[regionCode] || "Unknown Region";
  };

  const getLocaleName = (localeCode) => {
    const localeNames = {
      11: "City: Large (population of 250,000 or more)",
      12: "City: Midsize (population of at least 100,000 but less than 250,000)",
      13: "City: Small (population less than 100,000)",
      21: "Suburb: Large (outside principal city, in urbanized area with population of 250,000 or more)",
      22: "Suburb: Midsize (outside principal city, in urbanized area with population of at least 100,000 but less than 250,000)",
      23: "Suburb: Small (outside principal city, in urbanized area with population less than 100,000)",
      31: "Town: Fringe (in urban cluster up to 10 miles from an urbanized area)",
      32: "Town: Distant (in urban cluster more than 10 miles and up to 35 miles from an urbanized area)",
      33: "Town: Remote (in urban cluster more than 35 miles from an urbanized area)",
      41: "Rural: Fringe (rural territory up to 5 miles from an urbanized area or up to 2.5 miles from an urban cluster)",
      42: "Rural: Distant (rural territory more than 5 miles but up to 25 miles from an urbanized area or more than 2.5 and up to 10 miles from an urban cluster)",
      43: "Rural: Remote (rural territory more than 25 miles from an urbanized area and more than 10 miles from an urban cluster)"
    };
    return localeNames[localeCode] || "Unknown Locale";
  };

  const handleShowRounds = (collegeId, collegeName) => {
    setCurrentCollegeId(collegeId);
    setCurrentCollegeName(collegeName);
    setShowRoundsModal(true);
  };

  const handleSaveCustomDeadline = async (customEvent) => {
    const user = localStorage.getItem("user");
    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    const { token } = JSON.parse(user);

    try {
      await axios.post("https://api.student.youelite.net/api/events", customEvent, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Custom event saved successfully");
    } catch (error) {
      if (error.response) {
        console.error('Failed to save custom event:', error.response.data);
      } else {
        console.error('Failed to save custom event:', error);
      }
    }
  };



  const determineClassification = (collegeSAT, userSAT) => {
    if (!collegeSAT || isNaN(collegeSAT) || !userSAT) return null;
    const difference = userSAT - collegeSAT;

    if (difference < -100) return 'reach';
    if (difference > 100) return 'safety';
    return 'target';
  };

  const getRectangleColor = (classification) => {
    switch (classification) {
      case 'reach':
        return 'bg-danger';
      case 'target':
        return 'bg-warning';
      case 'safety':
        return 'bg-success';
      default:
        return 'bg-secondary'; // Default for no classification
    }
  };

  const mobileStyles = {
    mainTitle: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    inputGroup: {
        marginBottom: '20px',
    },
    summary: {
        marginBottom: '20px',
    }
  };



  return (
    <React.Fragment>
      <Header  />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <Row className="mb-4">
            <Col>
            <h4 className="main-title mb-0">College List</h4>
            </Col>
          </Row>

          <Row className="d-flex flex-column-reverse flex-md-row">
            <Col md={8}>
              <Row className="mb-4" style={mobileStyles.inputGroup}>
                <Col>
                  <div style={{ position: 'relative' }}>
                    <InputGroup>
                      <FormControl
                        placeholder="Search for a school"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        onBlur={handleSearchBlur}
                        onFocus={handleSearchFocus}
                      />
                      <Button onClick={handleSearch}>Search</Button>
                    </InputGroup>
                    {showSuggestions && suggestions.length > 0 && (
                      <Dropdown.Menu show style={{
                        display: 'block',
                        width: '100%',
                        maxHeight: '300px',
                        overflowY: 'auto',
                        zIndex: 500,
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                      }}>
                        {suggestions.map((suggestion, index) => (
                          <Dropdown.Item key={index} onClick={() => handleAddCollege(suggestion)}>
                            {suggestion.INSTNM}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    )}
                  </div>
                </Col>
              </Row>

              <Card className="card-college-list">
                <Card.Header>
                  <Card.Title as="h6">My College List</Card.Title>
                </Card.Header>
                <Card.Body className="college-list-body">
                  {loading ? (
                    <div className="text-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : colleges.length === 0 ? (
                    <p style={{ textAlign: 'center' }}>Your college list is empty. Start adding colleges!</p>
                  ) : (
                    colleges.map((college, index) => {
                      const classification = determineClassification(parseInt(college.SAT_AVG), userSAT);
                      const rectangleClass = getRectangleColor(classification);
                      const classificationText = classification ? classification.charAt(0).toUpperCase() + classification.slice(1) : 'N/A';

                      return (
                        <React.Fragment key={index}>
                          {index > 0 && <div className="divider"></div>}
                          <div className="college-item mb-3">
                            <Row>
                              <Col md="9">
                                <h5 onClick={() => handleCollegeClick(college)} style={{ cursor: 'pointer', color: '#0d6efd', position: 'relative', display: 'inline-block' }}>
                                  {college.COLLEGE_NAME_1 || college.INSTNM || "Unknown College"}
                                  {/* Classification Rectangle with Text */}
                                  <span
                                    className={`${rectangleClass}`}
                                    style={{
                                      display: 'inline-block',
                                      padding: '5px 10px',
                                      marginLeft: '10px', 
                                      color: '#fff',
                                      fontSize: '0.7em',
                                      borderRadius: '4px',
                                      verticalAlign: 'middle',  
                                    }}
                                  >
                                    {classificationText}
                                  </span>
                                </h5>
                                <p>{college.CITY || "Unknown City"}, {college.STABBR || "Unknown State"}</p>
                                <Row>
                                  <Col md="4">
                                    <p><strong>Acceptance rate:</strong> {formatAcceptanceRate(college.ADM_RATE)}</p>
                                    <p><strong>Students:</strong> {college.UGDS || "N/A"}</p>
                                  </Col>
                                  <Col md="4">
                                    <p><strong>Average SAT:</strong> {college.SAT_AVG || "N/A"}</p>
                                    <p><strong>Average Net Cost:</strong> {getDisplayCost(college)}</p>
                                  </Col>
                                  <Col md="4">
                                    <p><strong>Locale:</strong> {getLocaleName(college.LOCALE)}</p>
                                    <p><strong>Region:</strong> {getRegionName(college.REGION)}</p>
                                  </Col>
                                </Row>
                                <div className="d-flex justify-content-start align-items-center mt-2">
                                  <Button variant="outline-danger" onClick={() => handleRemoveCollege(college.userCollegeId)} className="mr-2">Remove</Button>
                                  <Button variant="link" onClick={() => handleShowRounds(college.UNITID, college.INSTNM)}>Change Round</Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </React.Fragment>
                      );
                    })
                  )}
                </Card.Body>
              </Card>

              {searchResults.length > 0 && (
                <Card className="card-one mt-4">
                  <Card.Header>
                    <Card.Title as="h6">Search Results</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    {searchResults.map((result, index) => (
                      <div key={index} className="college-item mb-3">
                        <Row>
                          <Col md="9">
                            <h5>{result.INSTNM}</h5>
                            <p>{result.CITY}, {result.STABBR}</p>
                            <Button onClick={() => handleAddCollege(result)}>Add to List</Button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              )}
            </Col>

            <Col xs={12} md={4} style={mobileStyles.summary}>
              <Summary colleges={colleges} userSAT={userSAT} userType={userType} />
            </Col>

          </Row>

          {renderUpgradeModal()}

          <Modal show={showProfileModal} onHide={() => setShowProfileModal(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>College Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedCollege && <CollegeProfile college={selectedCollege} />}
            </Modal.Body>
          </Modal>

          <CollegeRounds
            collegeId={currentCollegeId}
            collegeName={currentCollegeName}
            show={showRoundsModal}
            handleClose={() => setShowRoundsModal(false)}
            onSave={(round, isCustom) => {
              if (isCustom) {
                handleSaveCustomDeadline(round);
              } else {
                const updatedColleges = colleges.map(college =>
                  college.UNITID === currentCollegeId ? { ...college, admissionRound: round } : college
                );
                setColleges(updatedColleges);
              }
            }}
          />
        </div>
        <Footer className="footer"/>
      </div>
    </React.Fragment>
  );
};

export default CollegeList;

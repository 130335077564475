import React, { useEffect, useState, useMemo } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Col, Nav, OverlayTrigger, ProgressBar, InputGroup, FormControl, Modal, Row, Table, Tooltip, ListGroup, Spinner } from "react-bootstrap";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import { Doughnut } from 'react-chartjs-2';
import { FaBook, FaQuestionCircle, FaLightbulb, FaGem, FaSearch } from 'react-icons/fa';
import axios from "axios";
import debounce from "lodash.debounce";
import '../assets/css/UserDashboard.css'
import { calculateCollegeListBalance } from '../utils/collegeListUtils';
import CollegeProfile from "../dashboard/CollegeProfile";

export default function MainDashboard() {

  useEffect(() => {
    document.title = "Dashboard | YouElite";
  }, []);



  const [currentNotification, setCurrentNotification] = useState(0);
  const [friends, setFriends] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [currentResourceIndex, setCurrentResourceIndex] = useState(0);
  const [userInteractedActivities, setUserInteractedActivities] = useState(false);
  const [userInteractedResources, setUserInteractedResources] = useState(false);
  const [colleges, setColleges] = useState([]);
  const [collegeDetails, setCollegeDetails] = useState([]);
  const [loadingColleges, setLoadingColleges] = useState(true);
  const [loadingApplications, setLoadingApplications] = useState(true);
  const [summary, setSummary] = useState({
    reach: 0,
    target: 0,
    safety: 0,
    averagePrice: 0,
    totalColleges: 0,
  });
  const [userSAT, setUserSAT] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("user")); 
  const [showCollegeModal, setShowCollegeModal] = useState(false);
  const userType = user ? user.user_type : 1; 
  const userName = user ? user.name : "User";
  const [averageTimeToComplete, setAverageTimeToComplete] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [randomTip, setRandomTip] = useState(null);
  const [showAllColleges, setShowAllColleges] = useState(false);
  const [selectedCollegeUnitID, setSelectedCollegeUnitID] = useState(null);
  const listBalance = useMemo(() => {
    const safety = collegeDetails.filter(college => college.category === 'Safety').length;
    const target = collegeDetails.filter(college => college.category === 'Target').length;
    const reach = collegeDetails.filter(college => college.category === 'Reach').length;
    return calculateCollegeListBalance(safety, target, reach, collegeDetails.length);
  }, [collegeDetails]);
  const [randomArticle, setRandomArticle] = useState(null);
  const [predominantMajors, setPredominantMajors] = useState(null);
  const [randomFaq, setRandomFaq] = useState(null);
  const activeFreeTrial = user ? user.active_free_trial : false; 
  const handleShowSearchModal = () => setShowModal(true);
  
  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setSearchQuery("");  // Clear search input when closing
    setSearchResults([]);  // Clear results when closing
    setError(null);  // Clear any previous error
  };
  const handleCloseSearchModal = () => {
    setShowModal(false);
    setSearchQuery("");  // Clear search input when closing
    setSearchResults([]);  // Clear results when closing
    setError(null);  // Clear any previous error
  };





  useEffect(() => {
    const fetchUserSAT = async () => {
      try {
        const response = await axios.get('/user');
        setUserSAT(response.data.sat_score); // Assuming the API returns the user's SAT score in the "sat_score" field
      } catch (error) {
        console.error("Error fetching user SAT score:", error);
      }
    };

    fetchUserSAT();
  }, []);
  




  useEffect(() => {
    const fetchRandomFaq = async () => {
      try {
        const response = await axios.get('/random-faq');
        setRandomFaq(response.data); // Set the random FAQ data
      } catch (error) {
        console.error("Error fetching random FAQ:", error);
      }
    };
  
    fetchRandomFaq();
  }, []);
  

  useEffect(() => {
    const fetchApplications = async () => {
      setLoadingApplications(true);
      try {
        const response = await axios.get('/applications');
        const { applications, average_days_to_complete } = response.data;
  
        setApplications(applications); // Set applications array
        setAverageTimeToComplete(average_days_to_complete); // Set average days to complete
      } catch (error) {
        console.error("Error fetching applications:", error);
      } finally {
        setLoadingApplications(false); // Set loading to false when fetching is done
      }
    };
  
    const fetchFriends = async () => {
      try {
        const response = await axios.get('/friends'); // Fetch friends from the provided route
        setFriends(response.data); // Set friends state with the response data
      } catch (error) {
        console.error("Error fetching friends:", error);
      }
    };



    const fetchColleges = async () => {
      setLoadingColleges(true);
      try {
        const response = await axios.get('/user/colleges'); // Fetch colleges from the API
        const collegeData = response.data;
        setColleges(collegeData);
  
        // After getting colleges, fetch their details
        const collegeDetailsPromises = collegeData.map(college =>
          axios.get(`/user-colleges/dashboard/${college.college_id}`).then(response => {
            const collegeDetails = response.data;
            const collegeSAT = parseInt(collegeDetails.SAT_AVG);
  
            // Classify as Reach, Target, Safety
            let category;
            if (userSAT < collegeSAT - 100) {
              category = 'Reach';
            } else if (userSAT > collegeSAT + 100) {
              category = 'Safety';
            } else {
              category = 'Target';
            }
  
            return {
              name: collegeDetails.INSTNM,
              sat_avg: collegeDetails.SAT_AVG,
              NPT4_PUB: collegeDetails.NPT4_PUB,
              NPT4_PRIV: collegeDetails.NPT4_PRIV,
              category: category
            };
          })
        );
  
        // Wait for all college details to be fetched
        const detailedColleges = await Promise.all(collegeDetailsPromises);
        setCollegeDetails(detailedColleges);
  
        // Summarize the college data
        const totalColleges = detailedColleges.length;
        const reach = detailedColleges.filter(college => college.category === 'Reach').length;
        const safety = detailedColleges.filter(college => college.category === 'Safety').length;
        const target = detailedColleges.filter(college => college.category === 'Target').length;
        const totalPrice = detailedColleges.reduce((sum, college) => {
          const publicCost = parseInt(college.NPT4_PUB);
          const privateCost = parseInt(college.NPT4_PRIV);
          
          // Check if the cost is valid and not NaN
          if (!isNaN(publicCost)) {
            return sum + publicCost;
          } else if (!isNaN(privateCost)) {
            return sum + privateCost;
          }
          return sum;
        }, 0);
        
  
        const averagePrice = totalColleges > 0 ? totalPrice / totalColleges : 0;
  
        setSummary({
          reach: (reach / totalColleges) * 100,
          target: (target / totalColleges) * 100,
          safety: (safety / totalColleges) * 100,
          averagePrice: Math.round(averagePrice),
          totalColleges: totalColleges,
        });
      } catch (error) {
        console.error("Error fetching colleges data:", error);
      } finally {
        setLoadingColleges(false); // Set loading to false when fetching is done
      }
    };
  
    const fetchData = async () => {
      try {
        // Run these independent requests concurrently
        await Promise.all([fetchApplications(), fetchFriends(), fetchColleges()]);
      } catch (error) {
        console.error("Error in fetching data:", error);
      }
    };
  
    if (userSAT !== null) {
      fetchData(); // Call the function to fetch data
    }
  }, [userSAT]);

  useEffect(() => {
    const fetchRandomArticle = async () => {
      try {
        const response = await axios.get('/random-article');
        setRandomArticle(response.data); // Set the random article data
      } catch (error) {
        console.error("Error fetching random article:", error);
      }
    };
  
    fetchRandomArticle();
  }, []);
  
  useEffect(() => {
    if (applications.length > 0) {
        const majorCounts = applications.reduce((acc, app) => {
            acc[app.major] = (acc[app.major] || 0) + 1;
            return acc;
        }, {});

        const predominantMajor = Object.keys(majorCounts).reduce((a, b) => majorCounts[a] > majorCounts[b] ? a : b, '');
        setPredominantMajors(predominantMajor);
    }
  }, [applications]);



  useEffect(() => {
    const fetchRandomTip = async () => {
      try {
        const response = await axios.get('/random-weekly-tip');
        setRandomTip(response.data); // Set the random tip data
      } catch (error) {
        console.error("Error fetching random tip:", error);
      }
    };
  
    fetchRandomTip();
  }, []);
  

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get('/notifications');
        const recentNotifications = response.data.notifications.filter(notification => {
          const notificationDate = new Date(notification.created_at);
          const currentDate = new Date();
          const timeDifference = Math.abs(currentDate - notificationDate);
          const daysDifference = timeDifference / (1000 * 3600 * 24);
          return daysDifference <= 7; // Only keep notifications from the last 7 days
        });
        setNotifications(recentNotifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
  
    fetchNotifications();
  }, []);
  

  const applicationStatusData = useMemo(() => {
    const statusCounts = applications.reduce((acc, app) => {
      acc[app.status] = (acc[app.status] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: ['Not Started', 'In Progress', 'Submitted', 'Accepted', 'Rejected'],
      datasets: [
        {
          data: [
            statusCounts['not started'] || 0,
            statusCounts['in progress'] || 0,
            statusCounts['submitted'] || 0,
            statusCounts['accepted'] || 0,
            statusCounts['rejected'] || 0,
          ],
          backgroundColor: ['#6c5ce7', '#0984e3', '#007bff', '#28a745', '#dc3545'],
          hoverBackgroundColor: ['#a29bfe', '#74b9ff', '#4287f5', '#55efc4', '#fab1a0'],
        },
      ],
    };
  }, [applications]);
  
  const numberOfApplications = applications.length;
  const moneySpent = applications.reduce((total, app) => {
    return total + parseFloat(app.application_fee || 0);
  }, 0);
  



  const [skin, setSkin] = useState(localStorage.getItem('skin-mode') || '');

  // Define regStyle based on the skin mode
  const regStyle = {
    selected: {
      fill: "#506fd9"
    },
    initial: {
      fill: skin === 'dark' ? "#192030" : "#d9dde7"
    }
  };


  
  const handleCollegeClick = async (college) => {
    const user = localStorage.getItem("user");
    if (!user) {
      return;
    }
  
    const { token } = JSON.parse(user);
  
    try {
      const response = await axios.get(`https://api.student.youelite.net/api/colleges/${college.UNITID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setSelectedCollege(response.data);  // Store the college details
      setShowProfileModal(true);          // Show the modal
    } catch (error) {
      console.error("Failed to fetch college details:", error);
    }
  };
  









  const fetchSuggestions = debounce(async (query) => {
    if (!query) {
      setSuggestions([]);  // Clear suggestions if input is empty
      return;
    }

    const user = localStorage.getItem("user");
    if (!user) {
      return;
    }

    const { token } = JSON.parse(user);

    try {
      const response = await axios.get(`https://api.student.youelite.net/api/colleges?search=${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setSuggestions(response.data.data);  // Update suggestions with API response
    } catch (error) {
      console.error("Failed to fetch suggestions:", error);
    }
  }, 300);


  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const handleSearch = async () => {
    if (!searchQuery) {
      setError("Please enter a valid search query.");
      return;
    }

    setLoading(true);  // Start loading spinner
    setError(null);  // Clear any previous error

    const user = localStorage.getItem("user");
    if (!user) {
      setError("User is not authenticated.");
      setLoading(false);
      return;
    }

    const { token } = JSON.parse(user);

    try {
      const response = await axios.get(`https://api.student.youelite.net/api/colleges?search=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const results = response.data.data;

      if (results && results.length > 0) {
        setSearchResults(results);  // Store search results in state
        setError(null);  // Clear error since we have results
      } else {
        setSearchResults([]);  // Clear previous results if no matches found
        setError("No colleges found matching your search.");
      }

      setLoading(false);  // Stop loading spinner

    } catch (error) {
      console.error("Failed to search colleges:", error);
      setError("Failed to fetch search results. Please try again.");
      setLoading(false);  // Stop loading spinner
    }
  };

  // Handle adding a college to the user's list
  const handleAddCollege = async (college) => {
    const user = localStorage.getItem("user");
    if (!user) {
      return;
    }

    const { token } = JSON.parse(user);

    try {
      await axios.post("https://api.student.youelite.net/api/user/colleges", { college_id: college.UNITID }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      console.log(`Added college: ${college.INSTNM}`);
    } catch (error) {
      console.error("Failed to add college:", error);
    }
  };


  const handleOpenCollegeModal = (UNITID) => {
    setSelectedCollegeUnitID(UNITID);  // Store the UNITID of the selected college
    setShowCollegeModal(true);  // Show the college modal
  };


  const handleCloseCollegeModal = () => {
    setShowCollegeModal(false);  // Hide the college modal
  };





  const activities = useMemo(() => [
    {
      type: 'Recent Activity',
      items: [
        { icon: 'ri-heart-3-line', text: 'David liked your photo.' },
        { icon: 'ri-heart-3-line', text: 'David liked your photo.' },
      ]
    },
    {
      type: 'Friend List',
      items: friends.slice(0, 2).map((friend) => ({
        name: friend.name,
        avatarUrl: friend.avatarUrl // Use avatarUrl if provided
      }))
    }
  ], [friends]);



  const currentActivity = activities[currentActivityIndex];

  const nextActivity = () => {
    setUserInteractedActivities(true); // Mark that the user has interacted with activities
    setCurrentActivityIndex((prevIndex) => (prevIndex + 1) % activities.length);
  };

  const previousActivity = () => {
    setUserInteractedActivities(true); // Mark that the user has interacted with activities
    setCurrentActivityIndex((prevIndex) => (prevIndex - 1 + activities.length) % activities.length);
  };

  const resources = useMemo(() => [
    {
      type: 'Guide',
      icon: <FaBook className="me-2 text-primary" />,
      title: randomArticle ? randomArticle.title : 'Loading Guide...',
      description: randomArticle ? randomArticle.description : 'Fetching a random guide...',
      more: randomArticle ? randomArticle.body : 'Please wait while we load your guide.',
      related: randomArticle ? randomArticle.related : [],  // Assuming the API may return related articles
      link: randomArticle ? randomArticle.link : '#'
    },
    {
      type: 'FAQ',
      icon: <FaQuestionCircle className="me-2 text-warning" />,
      title: randomFaq ? randomFaq.title : 'Loading FAQ...',
      description: randomFaq ? randomFaq.description : 'Fetching a random FAQ...',
      more: randomFaq ? randomFaq.body : 'Please wait while we load your FAQ.',
      related: [],  // Add related items if available
      link: '/faq'
    },
    {
      type: 'Tip',
      icon: <FaLightbulb className="me-2 text-success" />,
      title: randomTip ? randomTip.title : 'Loading Tip...',
      description: randomTip ? randomTip.description : 'Fetching a random tip...',
      more: randomTip ? randomTip.body : 'Please wait while we load your tip.',
      related: [],  // Assuming no related resources for tips
      category: randomTip ? randomTip.category : null, // Display the category instead of progress
      link: '#'
    }
  ], [randomTip, randomFaq, randomArticle]);
  


  useEffect(() => {
    if (userInteractedResources) return; // Stop automatic rotation if user interacted with resources

    const interval = setInterval(() => {
      setCurrentResourceIndex((prevIndex) => (prevIndex + 1) % resources.length);
    }, 7000); // Change content every 6.5 seconds

    return () => clearInterval(interval);
  }, [resources.length, userInteractedResources]);

  const currentResource = resources[currentResourceIndex];
  const nextResource = () => {
    setUserInteractedResources(true);
    setCurrentResourceIndex((prevIndex) => (prevIndex + 1) % resources.length);
  };

  const previousResource = () => {
    setUserInteractedResources(true);
    setCurrentResourceIndex((prevIndex) => (prevIndex - 1 + resources.length) % resources.length);
  };


  const nextNotification = () => {
    setCurrentNotification((prev) => (prev + 1) % notifications.length);
  };
  
  const previousNotification = () => {
    setCurrentNotification((prev) => (prev - 1 + notifications.length) % notifications.length);
  };
  
 
  const DidYouKnowCard = () => {
    const [randomFact, setRandomFact] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchRandomFact = async () => {
        try {
          const response = await axios.get('https://api.student.youelite.net/api/random-fact');
          if (response.data && response.data.data && response.data.data.fact) {
            setRandomFact(response.data.data.fact);
            setError(null);
          } else {
            setError("Failed to fetch the fact.");
          }
        } catch (error) {
          console.error("Error fetching random fact:", error);
          setError("Error fetching random fact.");
        } finally {
          setLoading(false);
        }
      };
  
      fetchRandomFact();
    }, []); // Empty dependency array ensures this effect runs only once on mount
  
    return (
      <Card
        className="card-one"
        style={{
          position: 'relative',
          borderRadius: '10px',
          backgroundColor: '#ffffff',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)',
        }}
      >
        <Card.Header className="d-flex justify-content-between align-items-center" style={{ padding: '15px 20px' }}>
          <Card.Title as="h6" className="mb-0">Did You Know?</Card.Title>
        </Card.Header>
      
        <Card.Body
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            textAlign: 'center',
            fontSize: '1rem',
            lineHeight: '1.4',
            color: '#343a40',
            padding: '15px 10px',
          }}
        >
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : error ? (
            <p style={{ color: 'red' }}>{error}</p>
          ) : (
            <p
              style={{
                fontWeight: '500',
                color: '#343a40',
                marginBottom: '0',
              }}
            >
              {randomFact}
            </p>
          )}
        </Card.Body>
      </Card>
    );
  };
 
 
 
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">

        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Welcome {userName}!</h4>
          </div>

          <Nav as="nav" className="nav-icon nav-icon-lg" style={{ display: 'none' }}>
            <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
              <Nav.Link href=""><i className="ri-share-line"></i></Nav.Link>
            </OverlayTrigger>
          </Nav>
        </div>

        <Row className="g-3 justify-content-center">
          <Col md="6" xl="4">
            <Card className="card-one" style={{ borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
              {!activeFreeTrial && ( // Modify this condition to show the new message
                <Card.Header className="d-flex justify-content-between align-items-center" style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #dee2e6', borderRadius: '10px 10px 0 0' }}>
                  <Card.Title as="h6" className="mb-0">We Are Listening</Card.Title>
                </Card.Header>
              )}
              <Card.Body className="p-3">
                {activeFreeTrial ? (
                  <>
                    <h4 className="card-title">Enjoying the free trial?</h4>
                    <p>It’s just a taste—upgrade for the full buffet.</p>
                    <p>Upgrade your account and get the VIP treatment—because who doesn't want to level up?</p>
                    <div className="rectangle clickable">
                      <a href="/plans" className="upgrade-link">Click here to upgrade</a>
                    </div>
                  </>
                ) : (
                  <>
                    <p>We are constantly innovating and doing this for you, the student. Your feedback and suggestions helps us improve.</p>
                    <div className="rectangle clickable">
                      <a href="/support" className="support-link">Click here to share your feedback or your new idea!</a>
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>


          <Col md="6" xl="4">
            <Card className="card-one" style={{ borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
              <Card.Body>
                <h4 className="card-value mb-3">Notifications</h4>
                {notifications.length > 0 ? (
                  <div className="d-flex align-items-start mb-2">
                    <i className="ri-notification-3-line fs-5 text-primary me-3"></i>
                    <div>
                      <p className="mb-1 fw-bold">{notifications[currentNotification].data.subject}</p>
                      <p className="mb-0 text-muted">{notifications[currentNotification].data.message}</p>
                    </div>
                  </div>
                ) : (
                  <p className="text-muted">No notifications found</p>
                )}
                {notifications.length > 1 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <button 
                      className="btn btn-sm btn-light" 
                      onClick={previousNotification} 
                      aria-label="Previous Notification">
                      <i className="ri-arrow-left-s-line"></i>
                    </button>
                    <span className="text-muted">+{notifications.length - 1} more</span>
                    <button 
                      className="btn btn-sm btn-light" 
                      onClick={nextNotification} 
                      aria-label="Next Notification">
                      <i className="ri-arrow-right-s-line"></i>
                    </button>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>



          <Col md="6" xl="4" className="d-flex align-items-start">
            <Card 
              className="card-one" 
              style={{ 
                position: 'relative', 
                overflow: 'visible', 
                borderRadius: '10px', 
                backgroundColor: '#ffffff', 
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' 
              }}
            >
              <Card.Body>
                <h4 className="card-value mb-3">Quick College Search</h4>
                <p className="card-title text-dark mb-3">Find your desired college quickly</p>

                {/* Button that triggers the modal */}
                <div className="text-center">
                  <Button variant="primary" onClick={handleShowSearchModal}>
                    <FaSearch className="me-2" /> Search Colleges
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Modal show={showModal} onHide={handleCloseSearchModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Search Colleges</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Search Input */}
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Enter college name"
                  value={searchQuery}
                  onChange={handleSearchInputChange}  // Update search query
                  aria-label="Search"
                  aria-describedby="search-college"
                />
                <Button variant="primary" id="search-college" onClick={handleSearch}>
                  Search
                </Button>
              </InputGroup>

              {/* Loading Spinner */}
              {loading && (
                <div className="text-center my-3">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}

              {/* Error Message */}
              {error && !loading && (
                <p className="text-danger text-center">{error}</p>
              )}

              {/* Search Results */}
              {!loading && searchResults.length > 0 && (
                <ListGroup>
                  {searchResults.map((college, index) => (
                    <ListGroup.Item
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                      onClick={() => handleCollegeClick(college)}  // Open College Modal on click
                      style={{ cursor: 'pointer' }}
                    >
                      <span>{college.INSTNM}</span>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={(e) => handleAddCollege(college, e)}  // Pass `e` to handleAddCollege
                      >
                        Add to List
                      </Button>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}

              {/* No Results Found */}
              {!loading && searchResults.length === 0 && searchQuery && (
                <p className="text-center text-muted">No colleges found matching your search.</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseSearchModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showProfileModal} onHide={() => setShowProfileModal(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>College Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedCollege && <CollegeProfile college={selectedCollege} />}
            </Modal.Body>
          </Modal>








          {searchResults.length > 0 && (
            <Col md="12" style={{overflow: 'visible'}}>
              <Card className="card-one" style={{overflow: 'visible'}}>
                <Card.Header>
                  <Card.Title as="h6">Search Results</Card.Title>
                </Card.Header>
                <Card.Body>
                  {searchResults.map((result, index) => (
                    <div key={index} className="college-item mb-3">
                      <Row>
                        <Col md="9">
                          <h5>{result.INSTNM}</h5>
                          <p>{result.CITY}, {result.STABBR}</p>
                          <Button onClick={() => handleAddCollege(result)}>Add to List</Button>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          )}



          <Col md="7" xl="8">
            <Card className="card-one" style={{ borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  {currentResource.icon}
                  <Card.Title as="h6" className="mb-0">{currentResource.type}</Card.Title>
                </div>
                <div className="d-flex">
                  <Button variant="light" size="sm" onClick={previousResource}>&lt;</Button>
                  <Button variant="light" size="sm" onClick={nextResource}>&gt;</Button>
                </div>
              </Card.Header>
              <Card.Body>
                <Link to={currentResource.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <h5 className="fw-bold">{currentResource.title}</h5>
                  <p className="mb-2">{currentResource.description}</p>
                  <p className="mb-4 small" style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    {currentResource.more}
                  </p>
                </Link>

                <Row className="mb-4">
                  <Col md="6">
                    <h6>Related Resources:</h6>
                    <ul className="list-unstyled mb-0">
                      {(currentResource.related || []).map((item, index) => (
                        <li key={index} className="text-primary">
                          <i className="ri-arrow-right-s-line me-1"></i> {item}
                        </li>
                      ))}
                    </ul>
                  </Col>
                  {currentResource.type === 'Tip' && currentResource.category && (
                    <Col md="6" className="d-flex flex-column align-items-start">
                      <div>
                        <h6>Category:</h6>
                        <p>{currentResource.category}</p>
                      </div>
                    </Col>
                  )}
                </Row>
              </Card.Body>

            </Card>
          </Col>






          <Col md="5" xl="4">
            <Card className="card-one" style={{ borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
              <Card.Header>
                <Card.Title as="h6">College List Summary</Card.Title>
              </Card.Header>
              <Card.Body className="p-3" style={{ filter: userType === 1 ? 'blur(5px)' : 'none' }}>
                {loadingColleges ? (
                  // Loading spinner while data is being fetched
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <p className="mt-2">Fetching college data...</p>
                  </div>
                ) : userSAT === null ? (
                  // Display message when user does not have SAT score
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <p className="fs-md fw-bold mb-3">In order to use this feature you must have a SAT score inside of your account</p>
                    <Button variant="primary" onClick={() => navigate('/settings')}>
                      Go to Settings
                    </Button>
                  </div>
                ) : summary.totalColleges === 0 ? (
                  // Placeholder when no colleges exist
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <p className="fs-md fw-bold mb-3">No colleges in the list</p>
                    <p className="fs-sm text-muted mb-1">Start adding colleges to see your summary here.</p>
                  </div>
                ) : (
                  // Existing content when colleges exist
                  <>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div>
                        <h2 className="performance-value mb-0 d-flex flex-column">
                          <span style={{ fontSize: '1.5rem', lineHeight: '1.2' }}>{listBalance}</span>
                          <small 
                            className={`d-flex align-items-center ${listBalance === 'Balanced' ? 'text-success' : 'text-warning'}`}
                            style={{ fontSize: '0.75rem', marginTop: '0.25rem' }}
                          >
                            <i className={`ri-arrow-${listBalance === 'Balanced' ? 'up' : 'down'}-line me-1`}></i>
                            {listBalance === 'Balanced' ? 'Well distributed' : 'Needs adjustment'}
                          </small>
                        </h2>
                      </div>
                      <div className="d-flex flex-column align-items-end">
                        <div className="d-flex align-items-center">
                          <p className="fs-sm text-muted mb-0 me-2">Avg. Net Price:</p>
                          <p className="fs-sm fw-medium mb-0">${summary.averagePrice}</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="fs-sm text-muted mb-0 me-2">Items:</p>
                          <p className="fs-sm fw-medium mb-0">{summary.totalColleges}</p>
                        </div>
                      </div>
                    </div>

                    <label className="card-title fs-sm fw-medium">Acceptance Distribution</label>

                    <ProgressBar className="progress-one ht-8 mt-2 mb-4">
                      <ProgressBar now={summary.safety} variant="success" />
                      <ProgressBar now={summary.target} variant="warning" />
                      <ProgressBar now={summary.reach} variant="danger" />
                    </ProgressBar>

                    <Table className="table-three">
                      <tbody>
                        {collegeDetails.slice(0, showAllColleges ? collegeDetails.length : 3).map((college, index) => (
                          <tr key={index} style={{ backgroundColor: 'transparent', borderBottom: 'none' }}>
                            <td><div className={`badge-dot bg-${college.category === 'Safety' ? 'success' : college.category === 'Reach' ? 'danger' : 'warning'}`}></div></td>
                            <td>{college.name}</td>
                            <td>{college.category}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    {collegeDetails.length > 3 && (
                      <Button 
                        variant="link" 
                        onClick={() => setShowAllColleges(!showAllColleges)}
                        className="mt-2"
                      >
                        {showAllColleges ? "Show Less" : "Show More"}
                      </Button>
                    )}
                  </>
                )}
              </Card.Body>

              {userType === 1 && (
                <div className="overlay-upgrade-userdash">
                  <div className="overlay-content-userdash">
                    <Button variant="primary" onClick={() => navigate('/plans')} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <FaGem />
                      Upgrade
                    </Button>
                  </div>
                </div>
              )}
            </Card>
          </Col>





          <Col md="6">
            <Card className="card-one" style={{ borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
              <Card.Header>
                <Card.Title as="h6">Application Summary</Card.Title>
              </Card.Header>
              <Card.Body>
                {loadingApplications ? (
                  // Loading spinner while data is being fetched
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <p className="mt-2">Fetching application data...</p>
                  </div>
                ) : applications.length === 0 ? (
                  // Placeholder when no applications exist
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <p className="fs-md fw-bold mb-3">No applications available</p>
                    <p className="fs-sm text-muted mb-1">Start applying to colleges to see your application summary here.</p>
                  </div>
                ) : (
                  // Existing content when applications exist
                  <Row>
                    <Col md="4" className="d-flex align-items-center justify-content-center">
                      <div style={{ position: 'relative', width: '180px', height: '180px' }}>
                        <Doughnut 
                          data={applicationStatusData} 
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                display: false // Disable the default legend
                              }
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3" className="d-flex align-items-center justify-content-center">
                      <div style={{ paddingLeft: '10px' }}>
                        <ul style={{ listStyle: 'none', padding: 0 }}>
                          {applicationStatusData.labels.map((label, index) => (
                            <li key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                              <span style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                backgroundColor: applicationStatusData.datasets[0].backgroundColor[index],
                                marginRight: '8px'
                              }}></span>
                              <span>{label}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                    <Col md="5" className="d-flex flex-column justify-content-center">
                      <p className="fs-sm text-muted mb-1">Number of Applications</p>
                      <p className="fs-md fw-bold mb-3">{numberOfApplications}</p>

                      <p className="fs-sm text-muted mb-1">Average Time to Complete</p>
                      <p className="fs-md fw-bold mb-3">{averageTimeToComplete ? `${averageTimeToComplete.toFixed(2)} days` : 'N/A'}</p>

                      <p className="fs-sm text-muted mb-1">Predominant Major</p>
                      <p className="fs-md fw-bold mb-3">{predominantMajors || 'N/A'}</p>

                      <p className="fs-sm text-muted mb-1">Money Spent</p>
                      <p className="fs-md fw-bold mb-0">${moneySpent.toFixed(2)}</p>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          </Col>


          <Col md="6">
            <DidYouKnowCard />
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  )
}
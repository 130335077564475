import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Link } from "react-router-dom";
import { ChevronDown, ChevronUp, Tag, Check } from 'react-feather';
import '../assets/css/Guide.css';

export default function Guide() {
    const [sections, setSections] = useState([]);
    const [expandedSections, setExpandedSections] = useState({});
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Course | YouElite";
        fetchData();
    }, []);    

    const fetchData = useCallback(async () => {
        try {
            const [sectionResponse, tagResponse] = await Promise.all([
                axios.get('/sections'),
                axios.get('/tags')
            ]);
            setSections(sectionResponse.data);
            setTags(tagResponse.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    }, []);

    const toggleSection = (index) => {
        setExpandedSections((prev) => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const toggleArticleRead = async (e, articleId, currentReadStatus) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const endpoint = currentReadStatus ? `/articles/${articleId}/unread` : `/articles/${articleId}/read`;
            await axios.post(endpoint);
            
            setSections(prevSections => 
                prevSections.map(section => ({
                    ...section,
                    subsections: section.subsections.map(subsection => ({
                        ...subsection,
                        articles: subsection.articles.map(article => 
                            article.id === articleId 
                                ? { ...article, is_read: !currentReadStatus }
                                : article
                        )
                    }))
                }))
            );
        } catch (error) {
            console.error("Error toggling article read status:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="content-wrapper">
                    <Container fluid className="guide-container">
                        <Row>
                        <Col lg="8">
                                <h4 className="guide-title">College 101 - The Complete Guide</h4>
                                {sections.map((section, secIdx) => (
                                    <Card key={secIdx} className="guide-card mb-3">
                                        <Card.Header 
                                            onClick={() => toggleSection(secIdx)}
                                            className="guide-card-header d-flex justify-content-between align-items-center"
                                        >
                                            <h5 className="guide-section-title">{section.title}</h5>
                                            {expandedSections[secIdx] ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                                        </Card.Header>
                                        {expandedSections[secIdx] && (
                                            <Card.Body>
                                                {section.subsections.map((subsection, subIdx) => (
                                                    <div key={subIdx} className="guide-subsection">
                                                        <h3 className="guide-subsection-title">{subsection.title}</h3>
                                                        <ul className="guide-article-list">
                                                            {subsection.articles.map((article, artIdx) => (
                                                                <li key={artIdx} className="guide-article-item">
                                                                <div className="d-flex align-items-center">
                                                                    {article.is_read && ( // Conditionally render the icon only if the article is read
                                                                    <div 
                                                                        className={`guide-article-read-status me-2 read`} 
                                                                        onClick={(e) => toggleArticleRead(e, article.id, article.is_read)}
                                                                    >
                                                                        <Check size={16} color="#28a745" />
                                                                    </div>
                                                                    )}
                                                                    <Link to={`/course/article/${article.id}`} className="guide-article-link">
                                                                    <h4 className="guide-article-title">{article.title}</h4>
                                                                    <p className="guide-article-description">{article.description}</p>
                                                                    </Link>
                                                                </div>
                                                                </li>
                                                            ))}
                                                            </ul>

                                                    </div>
                                                ))}
                                            </Card.Body>
                                        )}
                                    </Card>
                                ))}
                            </Col>
                            <Col lg="4">
                                <Card className="guide-sidebar-card">
                                    <Card.Body style={{display:"none"}}>
                                        <h3 className="guide-sidebar-title">Popular Tags</h3>
                                        <div className="guide-tags">
                                            {tags.map((tag, index) => (
                                                <Badge key={index} bg="light" text="dark" className="me-2 mb-2">
                                                    <Tag size={12} className="me-1" />
                                                    {tag.name}
                                                </Badge>
                                            ))}
                                        </div>
                                    </Card.Body>
                                    <Card.Body>
                                        <h4>Welcome!</h4>
                                        <p>Explore a comprehensive guide on finding the perfect major, applying to colleges, and much more—all available to you for free!</p>
                                        <p>
                                            <a href="/support">Suggest new content</a>
                                        </p>
                                    </Card.Body>

                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
}
import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Form, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import axios from 'axios';
import debounce from 'lodash.debounce'; // For debouncing search

export default function CollegeCostCalculator() {
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [housingChoice, setHousingChoice] = useState('on-campus');
  const [totalCost, setTotalCost] = useState(0);
  const [costBreakdown, setCostBreakdown] = useState({});
  const [futureEarnings, setFutureEarnings] = useState([]);
  const [averageDebt, setAverageDebt] = useState(30000);
  const [debtRepaymentDuration, setDebtRepaymentDuration] = useState(10); // Placeholder for debt repayment duration in years
  const [debtToEarningsPercentage, setDebtToEarningsPercentage] = useState(0); // Placeholder for debt as percentage of future earnings

  // State for customizable input fields
  const [efc, setEfc] = useState(0); // Expected Family Contribution
  const [additionalScholarships, setAdditionalScholarships] = useState(0); // Additional Scholarships/Grants
  const [partTimeWorkIncome, setPartTimeWorkIncome] = useState(0); // Part-time Work Income

  // State for surplus/deficit calculation
  const [surplusOrDeficit, setSurplusOrDeficit] = useState(0);

  // State for search bar
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Debounced search function
  const debouncedSearch = debounce((query) => {
    const token = JSON.parse(localStorage.getItem('user'))?.token;

    if (query.length >= 3) {
      axios
        .get(`https://api.student.youelite.net/api/colleges?search=${query}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          setSuggestions(response.data);
          setShowSuggestions(true);
        })
        .catch((error) => {
          console.error('Error fetching search suggestions', error);
        });
    }
  }, 300); // 300ms debounce

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSelectCollege = (college) => {
    setSelectedCollege(college);
    setSearchQuery(college.INSTNM);
    setShowSuggestions(false);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('user'))?.token;

    if (selectedCollege) {
      axios
        .post(
          'https://api.student.youelite.net/api/calculate-cost',
          {
            unitid: selectedCollege.UNITID,
            housing_choice: housingChoice,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          const { final_estimated_net_cost, breakdown } = response.data;
          setCostBreakdown(breakdown);
          setTotalCost(final_estimated_net_cost);

          // Calculate surplus/deficit
          const totalContributions = (isNaN(efc) ? 0 : efc) +
            (isNaN(additionalScholarships) ? 0 : additionalScholarships) +
            (isNaN(partTimeWorkIncome) ? 0 : partTimeWorkIncome);
          const calculatedSurplusOrDeficit = final_estimated_net_cost - totalContributions;
          setSurplusOrDeficit(calculatedSurplusOrDeficit);

          // Mock future earnings data
          const mockFutureEarnings = [
            { year: 1, salary: 65000 },
            { year: 5, salary: 85000 },
            { year: 10, salary: 120000 },
            { year: 15, salary: 150000 },
            { year: 20, salary: 180000 },
          ];
          setFutureEarnings(mockFutureEarnings);

          // Placeholder calculation for debt as a percentage of future earnings
          const totalEarnings = mockFutureEarnings.reduce((sum, { salary }) => sum + salary, 0);
          setDebtToEarningsPercentage(((averageDebt * debtRepaymentDuration) / totalEarnings) * 100);
        })
        .catch((error) => {
          console.error('Error fetching data', error);
        });
    }
  }, [selectedCollege, housingChoice, efc, additionalScholarships, partTimeWorkIncome, averageDebt, debtRepaymentDuration]);

  // Function to ensure non-negative values
  const handleNonNegativeInput = (setter) => (e) => {
    const value = parseFloat(e.target.value);
    setter(value >= 0 ? value : 0);
  };

  const costBreakdownSeries = Object.values(costBreakdown).filter((value, index) => {
    const keys = Object.keys(costBreakdown);
    return ['tuition', 'room_and_board', 'books_and_supplies', 'personal_expenses'].includes(keys[index]) && value > 0;
  });

  const costBreakdownLabels = ['tuition', 'room & board', 'books & supplies', 'personal expenses', 'scholarships', 'grants'];

  const donutOptions = {
    chart: { type: 'donut' },
    labels: costBreakdownLabels,
    colors: ['#FF5733', '#33FF57', '#3357FF', '#FF33A8', '#FFC733', '#33FFF1'], // Updated vibrant colors
    legend: { show: false },
    dataLabels: { enabled: false },
    tooltip: { enabled: true },
  };

  const lineChartOptions = {
    chart: { type: 'line', toolbar: { show: false } },
    xaxis: { categories: futureEarnings.map((e) => `Year ${e.year}`) },
    yaxis: { labels: { formatter: (value) => `$${value.toLocaleString()}` } },
    stroke: { curve: 'smooth', width: 3 },
    colors: ['#506fd9'],
    markers: { size: 4 },
  };

  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const getIcon = (category) => {
    const icons = {
      tuition: 'ri-bank-line',
      housing: 'ri-home-line',
      meals: 'ri-restaurant-line',
      books: 'ri-book-open-line',
      personalExpenses: 'ri-shopping-bag-line',
      transportation: 'ri-bus-line',
      scholarships: 'ri-medal-line',
      grants: 'ri-gift-line',
    };
    return icons[category] || 'ri-question-line';
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">College Cost Calculator</li>
            </ol>
            <h4 className="main-title mb-0">College Cost Calculator</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="8">
            <Card className="card-one">
              <Card.Body>
                <Row className="g-3 mb-4">
                  <Col md="6">
                    <Form.Group>
                      <Form.Label>Search for a College</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Start typing college name"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      {showSuggestions && suggestions.length > 0 && (
                        <Dropdown.Menu show>
                          {suggestions.map((suggestion) => (
                            <Dropdown.Item key={suggestion.UNITID} onClick={() => handleSelectCollege(suggestion)}>
                              {suggestion.INSTNM}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group>
                      <Form.Label>Select Housing Choice</Form.Label>
                      <Form.Select onChange={(e) => setHousingChoice(e.target.value)} value={housingChoice}>
                        <option value="on-campus">On-Campus</option>
                        <option value="off-campus">Off-Campus</option>
                        <option value="with-family">With Family</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <button className="btn btn-primary" onClick={() => {}}>Calculate Cost</button>

                {selectedCollege && (
                  <>
                    {/* Customizable Input Fields */}
                    <Row className="g-3 mb-4">
                      <Col md="4">
                        <Form.Group>
                          <Form.Label>Expected Family Contribution (EFC)</Form.Label>
                          <Form.Control
                            type="number"
                            value={efc}
                            onChange={handleNonNegativeInput(setEfc)}
                            placeholder="Enter EFC"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group>
                          <Form.Label>Additional Scholarships/Grants</Form.Label>
                          <Form.Control
                            type="number"
                            value={additionalScholarships}
                            onChange={handleNonNegativeInput(setAdditionalScholarships)}
                            placeholder="Enter Amount"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group>
                          <Form.Label>Part-time Work Income</Form.Label>
                          <Form.Control
                            type="number"
                            value={partTimeWorkIncome}
                            onChange={handleNonNegativeInput(setPartTimeWorkIncome)}
                            placeholder="Enter Income"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="g-3">
                      <Col md="6">
                        <Card className="card-one">
                          <Card.Body>
                            <h3 className="card-value mb-1">${totalCost.toLocaleString()}</h3>
                            <label className="card-label fw-semibold text-dark mb-1">Estimated Net Cost</label>
                            <p className="fs-sm text-secondary mb-0">Annual cost for attending {selectedCollege.INSTNM}</p>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md="6">
                        <Card className="card-one">
                          <Card.Body className="d-flex flex-column align-items-center">
                            <ReactApexChart
                              options={donutOptions}
                              series={costBreakdownSeries}
                              type="donut"
                              height={200}
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <Row className="g-3 mt-3">
                      <Col>
                        <Card className="card-one">
                          <Card.Header>
                            <Card.Title as="h6">Estimated Future Earnings & Debt Overview</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Row className="g-3">
                              <Col md="8">
                                <ReactApexChart
                                  options={lineChartOptions}
                                  series={[{ name: 'Salary', data: futureEarnings.map((e) => e.salary) }]}
                                  type="line"
                                  height={300}
                                />
                              </Col>
                              <Col md="4">
                                <div className="mb-4">
                                  <h6>Average Debt</h6>
                                  <p>${averageDebt.toLocaleString()}</p>
                                </div>
                                <div className="mb-4">
                                  <h6>Repayment Duration</h6>
                                  <p>{debtRepaymentDuration} years</p>
                                </div>
                                <div className="mb-4">
                                  <h6>Debt as % of Future Earnings</h6>
                                  <p>{debtToEarningsPercentage.toFixed(2)}%</p>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xl="4">
            {selectedCollege && (
              <>
                <Card className="card-one">
                  <Card.Header>
                    <Card.Title as="h6">Cost Breakdown</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    {Object.entries(costBreakdown)
                      .filter(([category]) =>
                        ['tuition', 'room_and_board', 'books_and_supplies', 'personal_expenses'].includes(category)
                      )
                      .map(([category, amount]) => (
                        <div key={category} className="d-flex align-items-center justify-content-between mb-3">
                          <div className="d-flex align-items-center">
                            <i className={`${getIcon(category)} fs-24 lh-1 me-2`}></i>
                            <span className="fs-sm text-secondary">{category}</span>
                          </div>
                          <span className={`fw-semibold ${amount < 0 ? 'text-success' : 'text-danger'}`}>
                            {amount < 0 ? '-' : '+'} ${Math.abs(amount).toLocaleString()}
                          </span>
                        </div>
                      ))}
                    <hr />
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="fs-sm fw-semibold">Net Total</span>
                      <span className="fw-bold">${totalCost.toLocaleString()}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="fs-sm fw-semibold">Surplus/Deficit</span>
                      <span className={`fw-bold ${surplusOrDeficit >= 0 ? 'text-danger' : 'text-success'}`}>
                        {surplusOrDeficit >= 0 ? (
                          <>
                            <i className="ri-alert-line me-1" style={{ fontSize: '16px', lineHeight: '1.5', verticalAlign: 'middle' }}></i> 
                            ${surplusOrDeficit.toLocaleString()}
                          </>
                        ) : (
                          <>
                            <i className="ri-check-line me-1" style={{ fontSize: '16px', lineHeight: '1.5', verticalAlign: 'middle' }}></i> 
                            ${Math.abs(surplusOrDeficit).toLocaleString()}
                          </>
                        )}
                      </span>
                    </div>
                    {surplusOrDeficit >= 0 ? (
                      <p className="mt-2">
                        You have a deficit of ${surplusOrDeficit.toLocaleString()}, which means you might need to cover this amount through loans or other means.
                      </p>
                    ) : (
                      <p className="mt-2">
                        You have a surplus of ${Math.abs(surplusOrDeficit).toLocaleString()}, meaning you have enough funds to cover your costs without needing loans.
                      </p>
                    )}
                  </Card.Body>

                </Card>
              </>
            )}
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}

import React, { useState, useEffect } from 'react';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Row, Form, InputGroup, Table, ProgressBar, Badge, Toast, Alert, Spinner } from "react-bootstrap";
import Select from 'react-select';
import { Link } from "react-router-dom";
import { VectorMap } from "@react-jvectormap/core";
import { usAea } from "@react-jvectormap/unitedstates";
import '../assets/css/CollegeDiscovery.css';
import { Range } from 'react-range';
import axios from 'axios';
import CollegeListModal from '../components/CollegeListModal';
import ProfileCollegeDetails from '../components/ProfileCollegeDetails';
import { FaGem } from 'react-icons/fa'; 

export default function CollegeSearch() {

  useEffect(() => {
    document.title = "College Discovery | YouElite";
  }, []);



  const [skin, setSkin] = useState('light');
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedMajors, setSelectedMajors] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [tuitionMin, setTuitionMin] = useState('');
  const [tuitionMax, setTuitionMax] = useState('');
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [acceptanceRate, setAcceptanceRate] = useState([0, 100]);
  const [averageSAT, setAverageSAT] = useState([400, 1600]);
  const [campusSize, setCampusSize] = useState('');
  const [control, setControl] = useState('');
  const [colleges, setColleges] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFullList, setShowFullList] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [highlightedStates, setHighlightedStates] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [selectedCampusSizes, setSelectedCampusSizes] = useState([]);
  const [selectedInstitutionTypes, setSelectedInstitutionTypes] = useState([]);
  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [savedSearches, setSavedSearches] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [profileStrength, setProfileStrength] = useState(null);
  const [collegeSuggestions, setCollegeSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 
  const [userType, setUserType] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState('');
  const [showMoreSuggestions, setShowMoreSuggestions] = useState(false); 

  const institutionTypeOptions = [
    { value: '1', label: 'Public' },
    { value: '2', label: 'Private nonprofit' },
    { value: '3', label: 'Private for-profit' },
  ];

  const campusSizeOptions = [
    { value: [-2], label: 'Not applicable' },
    { value: [0], label: '(Not classified)' },
    { value: [1, 2, 3, 4, 5], label: 'Two-year campuses' },
    { value: [6, 7, 8], label: 'Four-year, very small' },
    { value: [9, 10, 11], label: 'Four-year, small' },
    { value: [12, 13, 14], label: 'Four-year, medium' },
    { value: [15, 16, 17], label: 'Four-year, large' },
    { value: [18], label: 'Exclusively graduate/professional' },
  ];
  

  const locationOptions = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
    { value: 'DC', label: 'Washington D.C.' }
  ];


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUserType(user.user_type);
    }
  }, []);


  const fetchSavedSearches = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;

    if (!token) {
      console.error('Error: No token found in localStorage');
      return;
    }

    try {
      const response = await axios.get('https://api.student.youelite.net/api/saved-searches', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setSavedSearches(response.data);
    } catch (error) {
      console.error('Error fetching saved searches:', error);
    }
  };

  const saveSearch = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;
  
    if (!token) {
      console.error('Error: No token found in localStorage');
      return;
    }
  
    const searchParams = {
      state: selectedLocations.length > 0 ? selectedLocations.map(location => location.value) : null,
      majors: selectedMajors.length > 0 ? selectedMajors.map(major => major.value) : null,
      tuition_min: tuitionMin || null,
      tuition_max: tuitionMax || null,
      adm_rate_min: acceptanceRate[0] / 100,
      adm_rate_max: acceptanceRate[1] / 100,
      sat_avg_min: averageSAT[0],
      sat_avg_max: averageSAT[1],
      campus_size: selectedCampusSizes.length > 0 ? selectedCampusSizes.map(size => size.value) : null,
      control: selectedInstitutionTypes.length > 0 ? selectedInstitutionTypes.map(type => type.value) : null,
    };
  
    try {
      const response = await axios.post('https://api.student.youelite.net/api/saved-searches', {
        name: searchName,
        params: searchParams
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setSearchName('');
      fetchSavedSearches();
      setNotificationMessage('Search saved successfully!');
      setShowNotification(true);
    } catch (error) {
      console.error('Error saving search:', error);
      setNotificationMessage('Error saving search. Please try again.');
      setShowNotification(true);
    }
  };

  const applySavedSearch = (savedSearch) => {
    const { params } = savedSearch;
  
    // Set selected locations
    setSelectedLocations(params.state ? locationOptions.filter(loc => params.state.includes(loc.value)) : []);
  
    // Set selected majors
    setSelectedMajors(params.majors ? majorOptions.filter(maj => params.majors.includes(maj.value)) : []);
  
    // Set tuition range
    setTuitionMin(params.tuition_min || '');
    setTuitionMax(params.tuition_max || '');
  
    // Set acceptance rate
    setAcceptanceRate([
      params.adm_rate_min !== null ? params.adm_rate_min * 100 : 0,
      params.adm_rate_max !== null ? params.adm_rate_max * 100 : 100
    ]);
  
    // Set average SAT
    setAverageSAT([
      params.sat_avg_min !== null ? params.sat_avg_min : 400,
      params.sat_avg_max !== null ? params.sat_avg_max : 1600
    ]);
  
    // Set campus size
    setSelectedCampusSizes(params.campus_size ? campusSizeOptions.filter(size => 
      params.campus_size.some(savedSize => size.value.includes(savedSize))
    ) : []);
  
    // Set institution type
    setSelectedInstitutionTypes(params.control ? institutionTypeOptions.filter(type => params.control.includes(type.value)) : []);
  
    // Delay the application of filters to ensure state updates have completed
    setTimeout(() => {
      applyFilters(1);
    }, 0);
  };

  const deleteSavedSearch = async (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;

    if (!token) {
      console.error('Error: No token found in localStorage');
      return;
    }

    try {
      await axios.delete(`https://api.student.youelite.net/api/saved-searches/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchSavedSearches();
      setNotificationMessage('Search deleted successfully!');
      setShowNotification(true);
    } catch (error) {
      console.error('Error deleting saved search:', error);
      setNotificationMessage('Error deleting search. Please try again.');
      setShowNotification(true);
    }
  };

  useEffect(() => {
    fetchSavedSearches();
  }, []);

  const majorOptions = [
    { value: 'Agriculture, Agriculture Operations, And Related Sciences', label: 'Agriculture, Agriculture Operations, And Related Sciences' },
    { value: 'Natural Resources And Conservation', label: 'Natural Resources And Conservation' },
    { value: 'Architecture And Related Services', label: 'Architecture And Related Services' },
    { value: 'Area, Ethnic, Cultural, Gender, And Group Studies', label: 'Area, Ethnic, Cultural, Gender, And Group Studies' },
    { value: 'Communication, Journalism, And Related Programs', label: 'Communication, Journalism, And Related Programs' },
    { value: 'Communications Technologies/Technicians And Support Services', label: 'Communications Technologies/Technicians And Support Services' },
    { value: 'Computer And Information Sciences And Support Services', label: 'Computer And Information Sciences And Support Services' },
    { value: 'Personal And Culinary Services', label: 'Personal And Culinary Services' },
    { value: 'Education', label: 'Education' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Engineering Technologies And Engineering-Related Fields', label: 'Engineering Technologies And Engineering-Related Fields' },
    { value: 'Foreign Languages, Literatures, And Linguistics', label: 'Foreign Languages, Literatures, And Linguistics' },
    { value: 'Family And Consumer Sciences/Human Sciences', label: 'Family And Consumer Sciences/Human Sciences' },
    { value: 'Legal Professions And Studies', label: 'Legal Professions And Studies' },
    { value: 'English Language And Literature/Letters', label: 'English Language And Literature/Letters' },
    { value: 'Liberal Arts And Sciences, General Studies And Humanities', label: 'Liberal Arts And Sciences, General Studies And Humanities' },
    { value: 'Library Science', label: 'Library Science' },
    { value: 'Biological And Biomedical Sciences', label: 'Biological And Biomedical Sciences' },
    { value: 'Mathematics And Statistics', label: 'Mathematics And Statistics' },
    { value: 'Military Technologies And Applied Sciences', label: 'Military Technologies And Applied Sciences' },
    { value: 'Multi/Interdisciplinary Studies', label: 'Multi/Interdisciplinary Studies' },
    { value: 'Parks, Recreation, Leisure, And Fitness Studies', label: 'Parks, Recreation, Leisure, And Fitness Studies' },
    { value: 'Philosophy And Religious Studies', label: 'Philosophy And Religious Studies' },
    { value: 'Theology And Religious Vocations', label: 'Theology And Religious Vocations' },
    { value: 'Physical Sciences', label: 'Physical Sciences' },
    { value: 'Science Technologies/Technicians', label: 'Science Technologies/Technicians' },
    { value: 'Psychology', label: 'Psychology' },
    { value: 'Homeland Security, Law Enforcement, Firefighting And Related Protective Services', label: 'Homeland Security, Law Enforcement, Firefighting And Related Protective Services' },
    { value: 'Public Administration And Social Service Professions', label: 'Public Administration And Social Service Professions' },
    { value: 'Social Sciences', label: 'Social Sciences' },
    { value: 'Construction Trades', label: 'Construction Trades' },
    { value: 'Mechanic And Repair Technologies/Technicians', label: 'Mechanic And Repair Technologies/Technicians' },
    { value: 'Precision Production', label: 'Precision Production' },
    { value: 'Transportation And Materials Moving', label: 'Transportation And Materials Moving' },
    { value: 'Visual And Performing Arts', label: 'Visual And Performing Arts' },
    { value: 'Health Professions And Related Programs', label: 'Health Professions And Related Programs' },
    { value: 'Business, Management, Marketing, And Related Support Services', label: 'Business, Management, Marketing, And Related Support Services' },
    { value: 'History', label: 'History' }
];


  const regStyle = {
    initial: {
      fill: '#d9ebf7',
      fillOpacity: 1,
      stroke: 'none',
      strokeWidth: 0,
      strokeOpacity: 0
    },
    hover: {
      fill: '#b2d1e9',
      cursor: 'pointer'
    },
    selected: {
      fill: '#2196f3'
    },
    selectedHover: {}
  };

  const fetchColleges = async (params, page = 1, collectedStates = new Set(), collectedColleges = []) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;
  
    if (!token) {
      console.error('Error: No token found in localStorage');
      return [];
    }
  
    try {
      const response = await axios.get('https://api.student.youelite.net/api/colleges', {
        params: { ...params, page },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      console.log('API Request Params:', params);
      console.log('API Response:', response.data);
  
      response.data.data.forEach(college => {
        collectedStates.add(`US-${college.STABBR}`);
        collectedColleges.push(college);
      });
  
      if (page < response.data.last_page) {
        return fetchColleges(params, page + 1, collectedStates, collectedColleges);
      } else {
        setHighlightedStates([...collectedStates]);
        return collectedColleges;
      }
    } catch (error) {
      console.error('Error fetching colleges:', error);  // Log the error
  
      if (error.response && error.response.status === 400 && error.response.data) {
        console.log('Error data:', error.response.data);  // Log the response data
        setErrorAlertMessage('Too many results. Please refine your search criteria.');
        setShowErrorAlert(true);
  
        // Automatically dismiss the alert after 3 seconds
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 3000);
      } else {
        console.error('Unexpected error:', error);
      }
      return [];  // Return an empty array or handle it according to your needs
    }
  };
  

  const applyFilters = async (page = 1) => {
    setIsLoading(true);
    setHasSearched(true);
    const params = {
      search: '',
      state: selectedLocations.length > 0 ? selectedLocations.map(location => location.value) : null,
      majors: selectedMajors.length > 0 ? selectedMajors.map(major => major.value) : null,
      tuition_min: tuitionMin || null,
      tuition_max: tuitionMax || null,
      adm_rate_min: acceptanceRate[0] / 100,
      adm_rate_max: acceptanceRate[1] / 100,
      sat_avg_min: averageSAT[0],
      sat_avg_max: averageSAT[1],
      campus_size: selectedCampusSizes.length > 0 
      ? selectedCampusSizes.flatMap(size => size.value) 
      : null,
      control: selectedInstitutionTypes.length > 0 ? selectedInstitutionTypes.map(type => type.value) : null,
      page: page
    };
  
    console.log('Filter Params:', params);
  
    const colleges = await fetchColleges(params, page);
    setColleges(colleges);
    setCurrentPage(page);
    setIsLoading(false);
  };

  const handleAddCollege = async (college) => {
    const user = localStorage.getItem("user");
    if (!user) {
      console.warn("User not authenticated");
      return;
    }

    const { token } = JSON.parse(user);

    try {
      const response = await axios.post(
        "https://api.student.youelite.net/api/user/colleges",
        { college_id: college.UNITID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(`College ${college.INSTNM} added successfully`);

      // Show success notification
      setNotificationMessage(`College ${college.INSTNM} added successfully!`);
      setShowNotification(true);

      // Hide notification after 3 seconds
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Show conflict notification
        setNotificationMessage(`College ${college.INSTNM} is already in your list.`);
        setShowNotification(true);

        // Hide notification after 3 seconds
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      } else {
        console.error("Failed to add college:", error);
      }
    }
  };

  const handleShowDetails = (college) => {
    setSelectedCollege(college);
    setDetailsModalShow(true);
  };

  const handleSuggestedCollegeClick = async (unitid) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;

    if (!token) {
      console.error('Error: No token found in localStorage');
      return;
    }

    try {
      const response = await axios.get(`https://api.student.youelite.net/api/colleges/${unitid}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const collegeData = response.data;
      setSelectedCollege(collegeData);
      setDetailsModalShow(true);
    } catch (error) {
      console.error('Error fetching college details:', error);
    }
  };





  const limitedColleges = showFullList ? colleges : colleges.slice(0, 5);

  useEffect(() => {
    console.log('Highlighted states updated: ', highlightedStates);
  }, [highlightedStates]);



  const fetchProfileStrength = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;

    if (!token) {
      console.error('Error: No token found in localStorage');
      return;
    }

    try {
      setIsLoading(true); // Start loading
      const userResponse = await axios.get('https://api.student.youelite.net/api/user', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const algoStrength = userResponse.data.algo_strength;
      setProfileStrength(algoStrength.profile_strength);

      const collegeDetails = await Promise.all(
        algoStrength.college_recommendations.map(async (recommendation) => {
          const collegeResponse = await axios.get(`https://api.student.youelite.net/api/colleges/${recommendation.unitid}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          return {
            name: collegeResponse.data.INSTNM,
            matchFactor: recommendation.match_factor * 100,
            unitid: recommendation.unitid,  // Make sure to include UNITID here
          };
        })
      );

      setCollegeSuggestions(collegeDetails);
    } catch (error) {
      console.error('Error fetching profile strength or college data:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };


  const recalculateProfileStrength = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;

    if (!token) {
      console.error('Error: No token found in localStorage');
      return;
    }

    try {
      setIsLoading(true); // Start loading

      // Call the API to recalculate profile strength
      await axios.post('https://api.student.youelite.net/api/calculate-profile-strength', {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // After recalculation, fetch the updated profile strength and college recommendations
      await fetchProfileStrength();

    } catch (error) {
      console.error('Error recalculating profile strength:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };




  useEffect(() => {
    fetchProfileStrength();
  }, []);


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      {showErrorAlert && (
        <div 
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%', 
            position: 'fixed',
            top: 80,  // Adjust the top position if necessary
            zIndex: 1050,
            pointerEvents: 'none' // Allows clicks to pass through the container
          }}
        >
          <Alert 
            variant="warning" 
            dismissible 
            onClose={() => setShowErrorAlert(false)}
            style={{
              width: '100%',
              maxWidth: '600px',
              pointerEvents: 'auto' 
            }}
          >
            <strong>Holy guacamole!</strong> {errorAlertMessage}
          </Alert>
        </div>
      )}

      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">College Search</li>
            </ol>
            <h4 className="main-title mb-0">College Discovery</h4>
          </div>
        </div>
        <Row className="g-3">
          <Col xl="3">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Advanced Filters</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Location</Form.Label>
                    <Select
                      options={locationOptions}
                      isMulti
                      isSearchable
                      value={selectedLocations}
                      onChange={setSelectedLocations}
                      placeholder="Select States"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Major</Form.Label>
                    <Select
                      options={majorOptions}
                      isMulti
                      isSearchable
                      value={selectedMajors}
                      onChange={setSelectedMajors}
                      placeholder="Select Majors"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Type of Institution</Form.Label>
                    <Select
                      options={institutionTypeOptions}
                      isMulti
                      isSearchable
                      value={selectedInstitutionTypes}
                      onChange={setSelectedInstitutionTypes}
                      placeholder="Select Institution Types"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Tuition Range (Sticker Price)</Form.Label>
                    <InputGroup>
                      <Form.Control type="number" placeholder="Min" value={tuitionMin} onChange={(e) => setTuitionMin(e.target.value)} />
                      <Form.Control type="number" placeholder="Max" value={tuitionMax} onChange={(e) => setTuitionMax(e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Campus Size</Form.Label>
                    <Select
                      options={campusSizeOptions}
                      isMulti
                      isSearchable
                      value={selectedCampusSizes}
                      onChange={setSelectedCampusSizes}
                      placeholder="Select Campus Sizes"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Acceptance Rate</Form.Label>
                    <div style={{ height: '36px' }}>
                      <Range
                        step={1}
                        min={0}
                        max={100}
                        values={acceptanceRate}
                        onChange={(values) => setAcceptanceRate(values)}
                        renderTrack={({ props, children }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: '6px',
                              width: '100%',
                              backgroundColor: '#ddd'
                            }}
                          >
                            {children}
                          </div>
                        )}
                        renderThumb={({ index, props }) => (
                          <div
                            {...props}
                            key={index}
                            style={{
                              ...props.style,
                              height: '17px',
                              width: '17px',
                              backgroundColor: '#506fd9',
                              borderRadius: '50%'
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <span>{acceptanceRate[0]}%</span>
                      <span>{acceptanceRate[1]}%</span>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Average SAT</Form.Label>
                    <div style={{ height: '36px' }}>
                      <Range
                        step={10}
                        min={400}
                        max={1600}
                        values={averageSAT}
                        onChange={(values) => setAverageSAT(values)}
                        renderTrack={({ props, children }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: '6px',
                              width: '100%',
                              backgroundColor: '#ddd'
                            }}
                          >
                            {children}
                          </div>
                        )}
                        renderThumb={({ index, props }) => (
                          <div
                            {...props}
                            key={index}
                            style={{
                              ...props.style,
                              height: '17px',
                              width: '17px',
                              backgroundColor: '#506fd9',
                              borderRadius: '50%'
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <span>{averageSAT[0]}</span>
                      <span>{averageSAT[1]}</span>
                    </div>
                  </Form.Group>


                  <Button variant="primary" className="w-100" onClick={() => applyFilters(1)}>Apply Filters</Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="9">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">College Map</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="ht-350 mb-4">
                  <VectorMap 
                    map={usAea} 
                    backgroundColor="transparent"
                    regionStyle={regStyle} 
                    selectedRegions={highlightedStates} 
                    zoomOnScroll={false} 
                    zoomButtons={false} 
                    key={highlightedStates.join(',')}
                  />
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <h6>Search Results</h6>
                  <div style={{display:"none"}}>
                    <Button variant="outline-secondary" size="sm" className="me-2">
                      <i className="ri-sort-asc"></i> Sort
                    </Button>
                    <Button variant="outline-secondary" size="sm">
                      <i className="ri-layout-grid-line"></i> View
                    </Button>
                  </div>
                </div>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>College Name</th>
                      <th>Location</th>
                      <th>Tuition</th>
                      <th>Acceptance Rate</th>
                      <th>Avg. SAT</th>
                      <th>Match</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    ) : hasSearched && colleges.length === 0 ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No results found for your search criteria.
                        </td>
                      </tr>
                    ) : (
                      colleges.map(college => (
                        <tr key={college.UNITID}>
                          <td>{college.INSTNM}</td>
                          <td>{college.CITY}, {college.STABBR}</td>
                          <td>${college.TUITIONFEE_IN.toLocaleString()}</td>
                          <td>{(college.ADM_RATE * 100).toFixed(2)}%</td>
                          <td>{college.SAT_AVG}</td>
                          <td>{college.SCH_DEG}</td>
                          <td className="d-flex gap-2">
                            <Button variant="primary" size="sm" onClick={() => handleShowDetails(college)}>Details</Button>
                            <Button variant="primary" size="sm" className="btn-list" onClick={() => handleAddCollege(college)}>List</Button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between">
                  {colleges.length > 5 && !showFullList && (
                    <Button variant="primary" onClick={() => setModalShow(true)}>
                      See Full List
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="g-3 mt-3">
          <Col xl="6">
            <div className="position-relative college-search-wrapper">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Profile-Based Suggestions</Card.Title>
                </Card.Header>
                <Card.Body style={userType === 1 ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>
                  {isLoading ? (
                    // Show spinner when loading
                    <div className="text-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      <p className="mt-2">Fetching profile suggestions...</p>
                    </div>
                  ) : profileStrength === null ? (
                    // Show message if profile strength hasn't been calculated
                    <div className="text-center">
                      <p>Your profile strength has not been calculated yet.</p>
                      <Button variant="primary" onClick={recalculateProfileStrength} disabled={isLoading}>
                        {isLoading ? 'Calculating...' : 'Calculate'}
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h6>Your Profile Strength</h6>
                        <Button variant="outline-primary" size="sm" onClick={recalculateProfileStrength} disabled={isLoading}>
                          {isLoading ? 'Recalculating...' : 'Recalculate'}
                        </Button>
                      </div>
                      <ProgressBar
                        now={profileStrength.toFixed(2)}
                        label={`${profileStrength.toFixed(2)}%`}
                        className="ht-15 mb-2"
                      />
                      <small className="text-muted">
                        Based on your academic performance and extracurricular activities. Results might be misleading, carry out your own research.
                      </small>
                      <h6 className="mt-3">Suggested Colleges</h6>
                      <ul className="list-group">
                        {(showMoreSuggestions ? collegeSuggestions : collegeSuggestions.slice(0, 5)).map((college, index) => (
                          <li 
                            key={index} 
                            className="list-group-item d-flex justify-content-between align-items-center"
                            onClick={() => handleSuggestedCollegeClick(college.unitid)}  // Added onClick event
                            style={{ cursor: 'pointer' }}  // Optional: Add a pointer cursor to indicate it’s clickable
                          >
                            {college.name}
                            <Badge bg="primary" pill>{college.matchFactor.toFixed(2)}% Match</Badge>
                          </li>
                        ))}
                      </ul>

                      {collegeSuggestions.length > 5 && (
                        <div className="mt-2 text-center">
                          <Button variant="outline-primary" size="sm" onClick={() => setShowMoreSuggestions(!showMoreSuggestions)}>
                            {showMoreSuggestions ? 'Show Less' : 'Show More'}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </Card.Body>

                {userType === 1 && (
                  <div className="college-search-upgrade-overlay">
                    <Link to="/plans" className="college-search-upgrade-button">
                      <Button variant="primary">
                        <FaGem className="me-1" /> Upgrade
                      </Button>
                    </Link>
                  </div>
                )}
              </Card>
            </div>
          </Col>

          <Col xl="6">
            <div className="position-relative college-search-wrapper">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Saved Searches</Card.Title>
                </Card.Header>
                <Card.Body style={userType === 1 ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>
                  <Form onSubmit={(e) => { e.preventDefault(); saveSearch(); }}>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Enter search name"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                      />
                      <Button variant="primary" type="submit">Save Current Search</Button>
                    </InputGroup>
                  </Form>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Search Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {savedSearches.map(search => (
                        <tr key={search.id}>
                          <td>{search.name}</td>
                          <td>
                            <Button variant="outline-primary" size="sm" className="me-2" onClick={() => applySavedSearch(search)}>Apply</Button>
                            <Button variant="outline-danger" size="sm" onClick={() => deleteSavedSearch(search.id)}>Delete</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
                {userType === 1 && (
                  <div className="college-search-upgrade-overlay">
                    <Link to="/plans" className="college-search-upgrade-button">
                      <Button variant="primary">
                        <FaGem className="me-1" /> Upgrade
                      </Button>
                    </Link>
                  </div>
                )}
              </Card>
            </div>
          </Col>
        </Row>

        <Footer />
      </div>

      <ProfileCollegeDetails
        show={detailsModalShow}
        onHide={() => setDetailsModalShow(false)}
        college={selectedCollege}
      />

      <CollegeListModal 
        show={modalShow} 
        handleClose={() => setModalShow(false)} 
        params={{
          search: '',
          state: selectedLocations.length > 0 ? selectedLocations.map(location => location.value) : null,
          majors: selectedMajors.length > 0 ? selectedMajors.map(major => major.value) : null,
          tuition_min: tuitionMin || null,
          tuition_max: tuitionMax || null,
          adm_rate_min: acceptanceRate[0] / 100,
          adm_rate_max: acceptanceRate[1] / 100,
          sat_avg_min: averageSAT[0],
          sat_avg_max: averageSAT[1],
          campus_size: campusSize || null,
          control: control || null,
        }} 
        token={JSON.parse(localStorage.getItem('user'))?.token}
      />

      <Toast
        onClose={() => setShowNotification(false)}
        show={showNotification}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          top: 20,
          right: 20,
          minWidth: 200,
          zIndex: 1000,
          animation: 'fadeIn 0.5s, fadeOut 0.5s 2.5s'
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{notificationMessage}</Toast.Body>
      </Toast>
    </React.Fragment>
  );
}
import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form, Button, Accordion, Spinner, Modal, Alert } from "react-bootstrap"; // Import Modal
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Doughnut, Radar } from 'react-chartjs-2';
import axios from "axios";
import "../assets/css/CollegeComparison.css";
import cipMapping from "../utils/CIPCodeMapping.json";
import { FaGem, FaTimes } from 'react-icons/fa'; // Import the gem icon

const getMajorName = (cipCode) => {
  return cipMapping[cipCode] || cipCode;
};

const getComparisonColor = (value, values, isHigherBetter = true) => {
  if (value === null || value === undefined || value === 'NA') return '';
  const numValues = values.filter(v => v !== null && v !== undefined && v !== 'NA').map(Number);
  if (numValues.length < 2) return '';
  const max = Math.max(...numValues);
  const min = Math.min(...numValues);
  const numValue = Number(value);
  if (isHigherBetter) {
    return numValue === max ? 'text-success-emphasis' : 'text-danger-emphasis';
  } else {
    return numValue === min ? 'text-success-emphasis' : 'text-danger-emphasis';
  }
};

const getFormattedValue = (value, isPercentage = false, values = [], isHigherBetter = true) => {
  if (value === null || value === undefined || isNaN(value)) {
    return 'NA';
  }
  const formattedValue = isPercentage ? `${(value * 100).toFixed(2)}%` : value;
  const colorClass = getComparisonColor(value, values, isHigherBetter);
  const style = colorClass.includes('success') ? { color: '#28a745', fontWeight: 'bold' } : { color: '#dc3545', fontWeight: 'bold' };
  return <span style={style}>{formattedValue}</span>;
};

export default function CollegeComparison() {

  useEffect(() => {
    document.title = "College Comparison | YouElite";
  }, []);


  const [selectedColleges, setSelectedColleges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showComparison, setShowComparison] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [comparisonResults, setComparisonResults] = useState([]);
  const [userFocus, setUserFocus] = useState(0);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false); // State for modal

  const stateNames = {
    AL: 'Alabama', AK: 'Alaska', AZ: 'Arizona', AR: 'Arkansas', CA: 'California', CO: 'Colorado',
    CT: 'Connecticut', DE: 'Delaware', FL: 'Florida', GA: 'Georgia', HI: 'Hawaii', ID: 'Idaho',
    IL: 'Illinois', IN: 'Indiana', IA: 'Iowa', KS: 'Kansas', KY: 'Kentucky', LA: 'Louisiana',
    ME: 'Maine', MD: 'Maryland', MA: 'Massachusetts', MI: 'Michigan', MN: 'Minnesota',
    MS: 'Mississippi', MO: 'Missouri', MT: 'Montana', NE: 'Nebraska', NV: 'Nevada',
    NH: 'New Hampshire', NJ: 'New Jersey', NM: 'New Mexico', NY: 'New York', NC: 'North Carolina',
    ND: 'North Dakota', OH: 'Ohio', OK: 'Oklahoma', OR: 'Oregon', PA: 'Pennsylvania', RI: 'Rhode Island',
    SC: 'South Carolina', SD: 'South Dakota', TN: 'Tennessee', TX: 'Texas', UT: 'Utah', VT: 'Vermont',
    VA: 'Virginia', WA: 'Washington', WV: 'West Virginia', WI: 'Wisconsin', WY: 'Wyoming', DC: 'District of Columbia'
  };

  const fetchUserFocus = async () => {
    try {
      const user = localStorage.getItem("user");
      if (!user) throw new Error("User not authenticated");

      const { token } = JSON.parse(user);
      if (!token) throw new Error("Authentication token not found");

      const response = await axios.get('https://api.student.youelite.net/api/user/focus', {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setUserFocus(response.data.focus);
    } catch (error) {
      console.error("Failed to fetch user focus:", error);
      setUserFocus(0);
    }
  };

  useEffect(() => {
    fetchUserFocus();
  }, []);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (searchQuery.length > 2) {
        try {
          const user = localStorage.getItem("user");
          if (!user) return;

          const { token } = JSON.parse(user);
          const response = await axios.get(`https://api.student.youelite.net/api/colleges?search=${searchQuery}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          setSuggestions(response.data.data);
        } catch (error) {
          console.error("Failed to fetch suggestions:", error);
        }
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [searchQuery]);

  const handleAddCollege = (college) => {
    if (selectedColleges.some(selected => selected.UNITID === college.UNITID)) return;
    if (selectedColleges.length < 4) {
      setSelectedColleges([...selectedColleges, college]);
      setSearchQuery("");
      setSuggestions([]);
    }
  };

  const handleRemoveCollege = (collegeId) => {
    setSelectedColleges(selectedColleges.filter(college => college.UNITID !== collegeId));
  };

  const compareCollegesHandler = async () => {
    setLoading(true);
    try {
      const user = localStorage.getItem("user");
      if (!user) throw new Error("User not authenticated");

      const { token } = JSON.parse(user);
      if (!token) throw new Error("Authentication token not found");

      const response = await axios.post('https://api.student.youelite.net/api/compare-colleges', {
        colleges: selectedColleges.map(college => college.UNITID),
        focus: userFocus
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setComparisonResults(response.data);
      setShowComparison(true);
    } catch (error) {
      console.error("Error in compareCollegesHandler:", error);

      // Check for the specific error code and show the modal
      if (error.response && error.response.status === 403 && error.response.data.error_code === 'PLAN_LIMIT_REACHED') {
        setShowUpgradeModal(true);
      } else {
        alert(`Error comparing colleges: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCompare = async () => {
    await compareCollegesHandler();
  };

  const handleBackToSearch = () => {
    setShowComparison(false);
  };

  const handleAccordionToggle = (eventKey) => {
    setOpenAccordion(openAccordion === eventKey ? null : eventKey);
  };

  const getFocusName = (focus) => {
    switch (focus) {
      case 1: return "Academic";
      case 2: return "Financial";
      case 3: return "Career";
      case 4: return "Social";
      default: return "Balanced";
    }
  };

  const getReasonForCategory = (category, topCollege, otherColleges) => {
    switch (category) {
      case 'academicFit':
        return `shines with a ${topCollege.C150_4 > Math.max(...otherColleges.map(c => c.C150_4)) ? 'superior' : 'strong'} completion rate of ${(topCollege.C150_4 * 100).toFixed(1)}% and a student-faculty ratio of ${topCollege.STUFACR}`;
      case 'financialFit':
        const costMetric = topCollege.NPT4_PUB || topCollege.NPT4_PRIV;
        return `offers a cost-effective option with an average annual cost of $${costMetric.toFixed(0)}, making it ${costMetric < Math.min(...otherColleges.map(c => c.NPT4_PUB || c.NPT4_PRIV)) ? 'more affordable' : 'competitively priced'} compared to other schools`;
      case 'careerOutcomes':
        return `prepares its graduates well with a median earning of $${topCollege.MN_EARN_WNE_P10.toFixed(0)} after 10 years, ${topCollege.MN_EARN_WNE_P10 > Math.max(...otherColleges.map(c => c.MN_EARN_WNE_P10)) ? 'higher' : 'on par'} with its peers`;
      case 'socialFit':
        return `provides a vibrant campus life with ${topCollege.UGDS.toFixed(0)} undergraduate students and a ${Math.abs(0.5 - topCollege.UGDS_WOMEN) < Math.min(...otherColleges.map(c => Math.abs(0.5 - c.UGDS_WOMEN))) ? 'well-balanced' : 'diverse'} gender ratio`;
      default:
        return 'offers a well-rounded experience';
    }
  };

  const radarChartData = (collegeIndex) => ({
    labels: ['Academics', 'Cost', 'Career Outcomes', 'Social Life'],
    datasets: [{
      label: comparisonResults[collegeIndex].INSTNM,
      data: [
        comparisonResults[collegeIndex].scores.academicFit,
        comparisonResults[collegeIndex].scores.financialFit,
        comparisonResults[collegeIndex].scores.careerOutcomes,
        comparisonResults[collegeIndex].scores.socialFit
      ],
      backgroundColor: `rgba(${collegeIndex * 60}, ${collegeIndex * 100}, ${255 - collegeIndex * 50}, 0.2)`,
      borderColor: `rgb(${collegeIndex * 60}, ${collegeIndex * 100}, ${255 - collegeIndex * 50})`,
      pointBackgroundColor: `rgb(${collegeIndex * 60}, ${collegeIndex * 100}, ${255 - collegeIndex * 50})`,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: `rgb(${collegeIndex * 60}, ${collegeIndex * 100}, ${255 - collegeIndex * 50})`
    }]
  });

  const radarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      r: {
        angleLines: {
          display: false
        },
        suggestedMin: 0,
        suggestedMax: 100
      }
    }
  };

  const doughnutChartData = (college) => ({
    labels: ['Tuition', 'Room & Board', 'Books & Supplies', 'Other Expenses'],
    datasets: [{
      data: [
        college.TUITIONFEE_OUT || 0,
        college.ROOMBOARD_ON || 0,
        college.BOOKSUPPLY || 0,
        college.OTHEREXPENSE_ON || 0
      ],
      backgroundColor: ['#506fd9', '#85b6ff', '#33d685', '#ffc107'],
      hoverBackgroundColor: ['#3a5dc9', '#75a6ef', '#23c675', '#efb107']
    }]
  });

  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  };

  const columnClass = `col-md-${Math.floor(12 / comparisonResults.length)}`;


  const renderUpgradeModal = () => (
    <Modal show={showUpgradeModal} onHide={() => setShowUpgradeModal(false)} centered>
      <Modal.Body className="text-center p-4" style={{ position: 'relative', backgroundColor: '#f8f9fa', borderRadius: '10px' }}>
        {/* Close Button in the body */}
        <FaTimes
          size={20}
          color="#6c757d"
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer'
          }}
          onClick={() => setShowUpgradeModal(false)}
        />

        <FaGem size={50} color="#6c757d" />
        <h4 className="my-4" style={{ color: '#333', fontWeight: '600' }}>Remember That Time You Saved a Ton of Money?</h4>
        <p>No? Well, today’s your chance!</p>
        <p>
        For just <del>$39.99</del> <span style={{ color: '#d4af37', fontWeight: 'bold' }}>$14.99</span>, upgrade to unlock all the college prep tools you need. Consider it the best money-saving move you’ve ever made (next to avoiding student loans)!
        </p>
        <Button
          variant="success"
          size="lg"
          onClick={() => { setShowUpgradeModal(false); window.location.href = "/plans"; }}
          style={{ fontWeight: '600', padding: '10px 20px', borderRadius: '30px' }}
        >
          Make a Financially Smart Move!
        </Button>
      </Modal.Body>
    </Modal>
  );


  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">College Comparison</li>
            </ol>
            <h4 className="main-title mb-0">Compare Colleges</h4>
            {showComparison && (
              <Button variant="link" onClick={handleBackToSearch} className="mb-0" style={{ textDecoration: 'none', paddingLeft: '0', marginTop: '10px' }}>
                <i className="ri-arrow-left-line" style={{ fontSize: '1.5rem', verticalAlign: 'middle' }}></i> Back to Search
              </Button>
            )}
          </div>
        </div>

        {!showComparison ? (
          <React.Fragment>
            <Card className="card-one">
              <Card.Body className="p-3">
                <h5 className="card-title fw-semibold">Choose between two and four colleges to compare!</h5>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Search for a college"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  {suggestions.length > 0 && (
                    <div className="suggestion-list">
                      {suggestions.map(college => (
                        <div key={college.UNITID} className="suggestion-item" onClick={() => handleAddCollege(college)}>
                          {college.INSTNM}
                        </div>
                      ))}
                    </div>
                  )}
                </Form.Group>
                <div className="selected-colleges mb-3">
                  {selectedColleges.map(college => (
                    <span key={college.UNITID} className="selected-college">
                      {college.INSTNM}
                      <button className="remove-btn" onClick={() => handleRemoveCollege(college.UNITID)}>
                        &times;
                      </button>
                    </span>
                  ))}
                </div>
                <Button
                  onClick={handleCompare}
                  disabled={selectedColleges.length < 2 || loading}
                  className={`w-100 ${selectedColleges.length < 2 ? 'opacity-50' : ''}`}
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{' '}
                      Loading...
                    </>
                  ) : (
                    "Compare Colleges"
                  )}
                </Button>
              </Card.Body>
            </Card>
            <div className="explanatory-text mt-1 p-3 d-flex align-items-center">
              <i className="ri-information-line" style={{ marginRight: '8px', fontSize: '1.5rem' }}></i>
              <h5 className="fw-bold mb-0">About The Feature</h5>
            </div>
            <p>
              Our College Comparison Service helps you make informed decisions by providing detailed and personalized comparisons of various colleges. <br></br>Start by searching for and selecting up to four colleges to compare side-by-side. Based on your profile and preferences, our system will recommend the college that best fits your needs. <br></br>
              We compare colleges across four main categories: Academic Fit, Financial Fit, Career Outcomes, and Social Fit. 
            </p>
              <ul>
                <li><strong>Academic Fit</strong> examines graduation and retention rates, student-faculty ratio, and more.</li>
                <li><strong>Financial Fit</strong> analyzes the average annual cost, financial aid availability, and overall affordability.</li>
                <li><strong>Career Outcomes</strong> looks at median earnings after graduation, employment rates, and loan repayment rates.</li>
                <li><strong>Social Fit</strong> considers student body composition, campus life, and housing options.</li>
              </ul>  
            <p>       
              Our intuitive charts and graphs make it easy to understand each category. You can expand and collapse sections to explore the data that matters most to you. To ensure consistency and comparability, we normalize all scores to a 0-100 scale, making it simple to see how colleges stack up against each other. We are always trying to improve this service. If you have any feedback, please click here.
              <br></br>Our goal is to provide you with clear, comprehensive, and personalized insights, helping you choose the college that best fits your academic, financial, career, and social preferences.
              <br></br><strong>Disclaimer:</strong> This tool is designed to assist students in comparing colleges, but it should not be considered definitive or used as the sole basis for making college decisions. Always carry out your own research.
            </p>
          </React.Fragment>
        ) : (
          <div className="comparison-container">
            <Card className="card-one mb-3">
              <Card.Body>
                <h6 className="card-title fw-semibold">Recommendation</h6>
                <p>Based on your {getFocusName(userFocus)} focused profile and preferences, we recommend:</p>
                <Alert variant="success">
                  <strong>{comparisonResults[0].INSTNM}</strong> seems to be the best fit for you!
                </Alert>
                <div className="recommendation-details mt-3">
                  <h6 className="fw-semibold">Why?</h6>
                  {['academicFit', 'financialFit', 'careerOutcomes', 'socialFit'].map((category) => {
                    const topCollege = comparisonResults[0];
                    const otherColleges = comparisonResults.slice(1);
                    const categoryName = {
                      academicFit: 'Academic Excellence',
                      financialFit: 'Affordability',
                      careerOutcomes: 'Career Prospects',
                      socialFit: 'Campus Life'
                    }[category];

                    return (
                      <div key={category} className="mb-2">
                        <strong className="text-primary">{categoryName}:</strong> {topCollege.INSTNM} {getReasonForCategory(category, topCollege, otherColleges)}.
                      </div>
                    );
                  })}
                </div>
              </Card.Body>
            </Card>

            <Row className="g-3">
              {comparisonResults.map((college, index) => (
                <Col key={college.UNITID} className={columnClass}>
                  <Card className="card-one mb-3">
                    <Card.Body>
                      <h5 className="fw-semibold">{college.INSTNM}</h5>
                      <p>{college.CITY}, {stateNames[college.STABBR]}</p>
                      <div style={{ height: "200px" }}>
                        <Radar data={radarChartData(index)} options={radarChartOptions} />
                      </div>

                      <Accordion activeKey={openAccordion} onSelect={handleAccordionToggle}>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><strong>Academic Fit</strong></Accordion.Header>
                          <Accordion.Body>
                            <div className="college-info mb-3">
                              <div className="info-item">
                                <strong>Completion Rate:</strong> {getFormattedValue(college.C150_4, true, comparisonResults.map(c => c.C150_4))}
                              </div>
                              <div className="info-item">
                                <strong>Retention Rate:</strong> {getFormattedValue(college.RET_FT4, true, comparisonResults.map(c => c.RET_FT4))}
                              </div>
                              <div className="info-item">
                                <strong>Majors Offered:</strong> {getMajorName(college.CIPCODE1)}, {getMajorName(college.CIPCODE2)}, ...
                              </div>
                              <div className="info-item">
                                <strong>Student-Faculty Ratio:</strong> {getFormattedValue(college.STUFACR, false, comparisonResults.map(c => c.STUFACR), false)}
                              </div>
                              <div className="info-item">
                                <strong>Faculty Composition:</strong> {getFormattedValue(college.PFTFAC, true, comparisonResults.map(c => c.PFTFAC))}
                              </div>
                              <div className="info-item">
                                <strong>Average Faculty Salary:</strong> ${getFormattedValue(college.AVGFACSAL, false, comparisonResults.map(c => c.AVGFACSAL))}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                          <Accordion.Header><strong>Financial Fit</strong></Accordion.Header>
                          <Accordion.Body>
                            <div className="college-info mb-3">
                              <div className="info-item">
                                <strong>Average Annual Cost:</strong> ${getFormattedValue(college.NPT4_PUB || college.NPT4_PRIV, false, comparisonResults.map(c => c.NPT4_PUB || c.NPT4_PRIV), false)}
                              </div>
                              <div className="info-item">
                                <strong> % PELL/Federal Loan Received:</strong> {getFormattedValue(college.PCTPELL, true, comparisonResults.map(c => c.PCTPELL))}, {getFormattedValue(college.PCTFLOAN, true, comparisonResults.map(c => c.PCTFLOAN))}
                              </div>
                              <div className="info-item">
                                <strong>Tuition and Fees:</strong> In-state: ${getFormattedValue(college.TUITIONFEE_IN, false, comparisonResults.map(c => c.TUITIONFEE_IN), false)}, Out-of-state: ${getFormattedValue(college.TUITIONFEE_OUT, false, comparisonResults.map(c => c.TUITIONFEE_OUT), false)}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header><strong>Career Outcomes</strong></Accordion.Header>
                          <Accordion.Body>
                            <div className="college-info mb-3">
                              <div className="info-item">
                                <strong>Median Earnings (10 years):</strong> ${getFormattedValue(college.MN_EARN_WNE_P10, false, comparisonResults.map(c => c.MN_EARN_WNE_P10))}
                              </div>
                              <div className="info-item">
                                <strong>Median Earnings (6 years):</strong> ${getFormattedValue(college.MN_EARN_WNE_P6, false, comparisonResults.map(c => c.MN_EARN_WNE_P6))}
                              </div>
                              <div className="info-item">
                                <strong>Employment Rate:</strong> {getFormattedValue((100 - college.UNEMP_RATE) / 100, true, comparisonResults.map(c => (100 - c.UNEMP_RATE) / 100))}
                              </div>
                              <div className="info-item">
                                <strong>Loan Repayment Rate:</strong> {getFormattedValue(college.RPY_3YR_RT, true, comparisonResults.map(c => c.RPY_3YR_RT))}
                              </div>
                              <div className="info-item">
                                <strong>Median Graduate Debt:</strong> ${getFormattedValue(college.GRAD_DEBT_MDN, false, comparisonResults.map(c => c.GRAD_DEBT_MDN), false)}
                              </div>
                              <div className="info-item">
                                <strong>Graduate School Enrollment Rate:</strong> {getFormattedValue(college.GRAD_ENROLL, true, comparisonResults.map(c => c.GRAD_ENROLL))}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                          <Accordion.Header><strong>Social Fit</strong></Accordion.Header>
                          <Accordion.Body>
                            <div className="college-info mb-3">
                              <div className="info-item">
                                <strong>Student Body Composition:</strong> {getFormattedValue(college.UGDS, false, comparisonResults.map(c => c.UGDS))}, Women: {getFormattedValue(college.UGDS_WOMEN, true, comparisonResults.map(c => c.UGDS_WOMEN))}, International: {getFormattedValue(college.UGDS_NRA, true, comparisonResults.map(c => c.UGDS_NRA))}
                              </div>
                              <div className="info-item">
                                <strong>Housing and Campus Life:</strong> On-campus: ${getFormattedValue(college.ROOMBOARD_ON, false, comparisonResults.map(c => c.ROOMBOARD_ON), false)}, Off-campus: ${getFormattedValue(college.ROOMBOARD_OFF, false, comparisonResults.map(c => c.ROOMBOARD_OFF), false)}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <h6 className="card-title fw-semibold mt-3">Cost Breakdown</h6>
                      <div style={{ height: "200px" }}>
                        <Doughnut data={doughnutChartData(college)} options={doughnutChartOptions} />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        )}

        {renderUpgradeModal()}
        <Footer />
      </div>
    </React.Fragment>
  );
}
